<div class="col-md-12 float-left customersearch-forms login-forms p-0 customer-home-forms mb-4">
  <form [formGroup]="returnOrdersSearchForm" (ngSubmit)="submitForm()">
    <div class="form-group mt-3 col-lg-2 pl-0 float-left mb-3 p-res-0">
      <input
        type="text"
        class="form-control w-100"
        [placeholder]="'orderReturnsSearchForm.placeholder.rma' | cxTranslate"
        formControlName="rma"
      />
      <span class="highlight"></span>
      <label class="text-muted">{{ 'orderReturnsSearchForm.label.rma' | cxTranslate }}</label>
    </div>
    <div class="form-group mt-3 col-lg-2 pl-0 float-left mb-3 p-res-0">
      <input
        type="text"
        class="form-control w-100"
        [placeholder]="'orderReturnsSearchForm.placeholder.accountNumber' | cxTranslate"
        formControlName="accountNumber"
      />
      <span class="highlight"></span>
      <label class="text-muted">{{ 'orderReturnsSearchForm.label.accountNumber' | cxTranslate }}</label>
    </div>
    <div class="form-group mt-3 col-lg-2 pl-0 float-left mb-3 p-res-0">
      <input
        type="text"
        class="form-control w-100"
        [placeholder]="'orderReturnsSearchForm.placeholder.accountName' | cxTranslate"
        formControlName="accountName"
      />
      <span class="highlight"></span>
      <label class="text-muted">{{ 'orderReturnsSearchForm.label.accountName' | cxTranslate }}</label>
    </div>
    <div class="form-group mt-3 col-lg-2 pl-0 float-left mb-3 p-res-0">
      <input
        type="text"
        class="form-control w-100"
        [placeholder]="'orderReturnsSearchForm.placeholder.poNumber' | cxTranslate"
        formControlName="purchaseOrderNumber"
      />
      <span class="highlight"></span>
      <label class="text-muted">{{ 'orderReturnsSearchForm.label.poNumber' | cxTranslate }}</label>
    </div>

    <div class="form-group mt-3 col-lg-2 pl-0 float-left mb-3 p-res-0 multi-select-styles">
      <ng-select  [items]="orderStatusList$ | async" [clearSearchOnAdd]="true"
      [closeOnSelect]="false" labelForId="state" [multiple]="true"  [placeholder]="'orderReturnsSearchForm.placeholder.status' | cxTranslate"
      clearAllText="Clear" formControlName="status" [bindLabel]="'Text'" [ngModel]="pStatus">
      <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
        <div class="row">
          <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"    [ngModelOptions]="{standalone: true}"/>
        {{item['Text']}}
      </div>
      </ng-template>
      <ng-template ng-multi-label-tmp let-items="items">
        <div class="ng-value" *ngFor="let item of items | slice:0:1">
          {{item['Text']}}
        </div>
        <div class="ng-value" *ngIf="items.length > 1">
          <span class="ng-value-label">{{items.length - 1}} more...</span>
        </div>
      </ng-template>
    </ng-select>

      <span class="highlight"></span>
      <label class="text-muted">{{ 'orderReturnsSearchForm.label.filterByStatus' | cxTranslate }}</label>
    </div>
    <!-- <div class="form-group mt-3 col-lg-2 pl-0 float-left mb-3 p-res-0">
      <ng-select
        class="mb-1"
        formControlName="status"
        bindLabel="Text"
        bindValue="Key"
        [items]="orderStatusList$ | async"
        [placeholder]="'orderReturnsSearchForm.placeholder.status' | cxTranslate"
        [searchable]="false"
        [clearable]="false"
        bindLabel="Text"
        bindValue="Key"
      >
      </ng-select>
      <span class="highlight"></span>
      <label class="text-muted">{{ 'orderReturnsSearchForm.label.filterByStatus' | cxTranslate }}</label>
    </div> -->
    <div class="form-group mt-3 col-lg-2 pl-0 float-left mb-3 p-res-0">
      <input
        type="text"
        class="form-control w-100"
        [placeholder]="'orderReturnsSearchForm.placeholder.productNumber' | cxTranslate"
        formControlName="productNumber"
      />
      <span class="highlight"></span>
      <label class="text-muted">{{ 'orderReturnsSearchForm.label.productNumber' | cxTranslate }}</label>
    </div>

    <div class="form-group mt-3 col-lg-2 pl-0 float-left mb-3 p-res-0">
      <input
        type="text"
        class="form-control w-100"
        [placeholder]="'orderReturnsSearchForm.placeholder.productName' | cxTranslate"
        formControlName="productName"
      />
      <span class="highlight"></span>
      <label class="text-muted">{{ 'orderReturnsSearchForm.label.productName' | cxTranslate }}</label>
    </div>

    <div class="form-group mt-3 col-lg-2 pl-0 float-left mb-3 p-res-0 multi-select-styles" *ngIf="brandSalesShipToList$ | async as items">
      <ng-select  [items]="items.brand" [clearSearchOnAdd]="true"
      [closeOnSelect]="false" labelForId="state" [multiple]="true"  [placeholder]="'orderReturnsSearchForm.placeholder.brand' | cxTranslate"
      clearAllText="Clear" formControlName="brand" [bindLabel]="'name'" [ngModel]="pBrand">
      <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
        <div class="row">
          <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"    [ngModelOptions]="{standalone: true}"/>
        {{item['name']}}
      </div>
      </ng-template>
      <ng-template ng-multi-label-tmp let-items="items">
        <div class="ng-value" *ngFor="let item of items | slice:0:1">
          {{item['name']}}
        </div>
        <div class="ng-value" *ngIf="items.length > 1">
          <span class="ng-value-label">{{items.length - 1}} more...</span>
        </div>
      </ng-template>
    </ng-select>

      <span class="highlight"></span>
      <label class="text-muted">{{ 'orderReturnsSearchForm.label.brand' | cxTranslate }}</label>
    </div>

    <!-- <div class="form-group mt-3 col-lg-2 pl-0 float-left mb-3 p-res-0" *ngIf="brandSalesShipToList$ | async as items">
      <ng-select
        class="mb-1"
        formControlName="brand"
        bindLabel="name"
        bindValue="value"
        [items]="items.brand"
        [placeholder]="'orderReturnsSearchForm.placeholder.brand' | cxTranslate"
        [searchable]="false"
        [clearable]="false"
      >
      </ng-select>
      <span class="highlight"></span>
      <label class="text-muted">{{ 'orderReturnsSearchForm.label.brand' | cxTranslate }}</label>
    </div> -->

    <div class="form-group mt-3 col-lg-2 pl-0 float-left mb-3 p-res-0 multi-select-styles" *ngIf="brandSalesShipToList$ | async as items">
      <ng-select  [items]="items.shipTo" [clearSearchOnAdd]="true"
      [closeOnSelect]="false" labelForId="state" [multiple]="true" [placeholder]="'orderReturnsSearchForm.placeholder.shipTo' | cxTranslate"
      clearAllText="Clear" formControlName="shipTo" [bindLabel]="'name'" [ngModel]="pShipTo">
      <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
        <div class="row">
          <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"    [ngModelOptions]="{standalone: true}"/>
        {{item['name']}}
      </div>
      </ng-template>
      <ng-template ng-multi-label-tmp let-items="items">
        <div class="ng-value" *ngFor="let item of items | slice:0:1">
          {{item['name']}}
        </div>
        <div class="ng-value" *ngIf="items.length > 1">
          <span class="ng-value-label">{{items.length - 1}} more...</span>
        </div>
      </ng-template>
    </ng-select>

      <span class="highlight"></span>
      <label class="text-muted">{{ 'orderReturnsSearchForm.label.shipTo' | cxTranslate }}</label>
    </div>

    <!-- <div class="form-group mt-3 col-lg-2 pl-0 float-left mb-3 p-res-0" *ngIf="brandSalesShipToList$ | async as items">
      <ng-select
        class="mb-1"
        formControlName="shipTo"
        bindLabel="name"
        bindValue="value"
        [items]="items.shipTo"
        [placeholder]="'orderReturnsSearchForm.placeholder.shipTo' | cxTranslate"
        [searchable]="false"
        [clearable]="false"
      >
      </ng-select>
      <span class="highlight"></span>
      <label class="text-muted">{{ 'orderReturnsSearchForm.label.shipTo' | cxTranslate }}</label>
    </div> -->

    <div class="form-group mt-3 col-lg-2 pl-0 float-left mb-2 p-res-0 valid-to">
      <div class="input-group">
        <input class="form-control w-100 mb-0" [placeholder]="'orderReturnsSearchForm.label.from' | cxTranslate"
          name="fromDate" ngbDatepicker #fromDate="ngbDatepicker" formControlName="_startDate" [readonly]="true" [maxDate]="maxStartDate()" (dateSelect)="onDateChange($event, 'fDate')" />
        <span class="highlight"></span>
        <label class="text-muted">{{ 'orderReturnsSearchForm.label.from' | cxTranslate }}</label>
          <div class="input-group-append">
          <button class="btn btn calendar p-0 h-auto w-auto" container="body" (click)="fromDate.toggle()"
            type="button">
            <i class="far fa-calendar-alt color-font header-icon-color"></i>
          </button>
          </div>
      </div>
      <ng-container *ngIf="returnOrdersSearchForm.controls['_startDate'].errors as errors">
        <div *ngIf="errors.required">
          Please select from date
        </div>
      </ng-container>
    </div>

    <div class="form-group mt-3 col-lg-2 pl-0 float-left mb-3 p-res-0 valid-to">
      <div class="input-group">
        <input class="form-control w-100 mb-0" [placeholder]="'orderReturnsSearchForm.label.to' | cxTranslate" name="endDate"
          ngbDatepicker #endDate="ngbDatepicker" formControlName="_endDate" [readonly]="true" [maxDate]="" (dateSelect)="onDateChange($event)" />
        <span class="highlight"></span>
        <label class="text-muted">{{ 'orderReturnsSearchForm.label.to' | cxTranslate }}</label>
        <div class="input-group-append d-inline-block">
          <button class="btn btn calendar p-0 h-auto w-auto" container="body" (click)="endDate.toggle()"
            type="button">
            <i class="far fa-calendar-alt color-font header-icon-color"></i>
          </button>
        </div>
      </div>
      <div *ngIf="returnOrdersSearchForm.controls['_endDate'].errors as errors" class="mt-25">
        Please select to date
      </div>
    </div>

    <div class="col-lg-12 p-0 float-left">
      <div class="col-lg-2 pl-0 p-res-0 float-left">
        <button type="submit" [disabled]="isSearchDisabled$ | async" class="w-100 btn btn-primary">
          {{ 'button.search' | cxTranslate }}
        </button>
      </div>
      <div class="col-lg-2 pl-0 p-res-0 float-left">
        <button type="button" (click)="resetForm()" class="w-100 btn btn-outline-primary">
          {{ 'button.reset' | cxTranslate }}
        </button>
      </div>
    </div>
  </form>
</div>
