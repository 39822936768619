import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { NgbPanelChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { Product, ProductScope, ProductService, RoutingService } from '@spartacus/core';
import { CurrentProductService, ProductSummaryComponent } from '@spartacus/storefront';
import { Observable, Subscription } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { KnBrCommonService } from 'src/app/services/kn-br-common.service';

@Component({
  selector: 'kn-br-product-info-detail',
  templateUrl: './kn-br-product-info-detail.component.html',
  styleUrls: ['./kn-br-product-info-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KnBrProductInfoDetailComponent extends ProductSummaryComponent implements OnDestroy {
  show = false;
  index;
  openedPanels =['panel1'];
  pdpProductData;
  // private currentProductCode$: Observable<string> = this.service.getProduct().pipe(
  //   filter(Boolean),
  //   map((p: Product) => p.code)
  // );

  subscription: Subscription;
  product$: Observable<Product | null> = this.currentProductService
    .getProduct([ProductScope.DETAILS])
    .pipe(tap((_) => {
      this.pdpProductData =_;
      this.knBrCommonService.setSpinner(false)}));
  constructor(
    protected service: CurrentProductService,
    protected productService: ProductService,
    protected routingService: RoutingService,
    private knBrCommonService: KnBrCommonService
  ) {
    super(service);
    //     this.subscription = this.routingService.getRouterState().subscribe((state) => {
    //       if (state.state.params['productCode'])
    // tslint:disable-next-line: max-line-length
    // // TODO:Spartacus - Method 'ProductService.reload' was removed. Please use the reloading triggers configuration instead (see https://sap.github.io/spartacus-docs/loading-scopes/#reloading-triggers for more).
    //         // this.productService.reload(state.state.params['productCode'], ProductScope.DETAILS);
    //     });
    this.knBrCommonService.setSpinner(false);
  }

  showHide($event: NgbPanelChangeEvent, acc) {
    const idx = $event.panelId;
    if (!acc.isExpanded($event.panelId)) {
      this.show = true;
      this.openedPanels.push(idx);
      this.index = idx;
    } else {
      let index = this.openedPanels.indexOf(idx);
      this.openedPanels.splice(index, 1);
      this.show = false;
    }
  }

  shouldProductandTechnicalDocumentationDisplay() {
    if(this.pdpProductData) {
      const product = this.pdpProductData;
      if (
        product.assetName ||
        product.assetNameEs ||
        product.assetNameFr ||
        product.productSellSheetName ||
        product.productSellSheetNameEs ||
        product.productSellSheetNameFr ||
        product.safetyAssetName ||
        product.safetyAssetNameEs ||
        product.safetyAssetNameFr
      ) {
        return true
      }
      return false;
    }
    return false;
  }

  ngOnDestroy() {
    // this.subscription.unsubscribe();
  }
}
