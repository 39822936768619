<div>
  <h4 class="pb-3">Invoice Items (Table Invoice List of Items)</h4>
  <small class="text-muted"><b>Terms and Conditions: </b>The document is pursuant to {{this.invoiceData?.brandName}} terms and conditions of sale in effect at the time of this document.</small>
</div>
<ng-container *ngIf="this.invoiceItems">
  <div class="customersearch-list-table mt-3 float-left col-lg-12 p-0">
    <div class="customersearch-list-titles border-top float-left w-100 pl-2 py-3 d-none d-sm-none d-lg-block">
      <div class="col-2 float-left font-text-600 pl-2">
        {{ 'knbrInvoiceDetails.label.materialNum' | cxTranslate }}
      </div>
      <div class="col-4 float-left font-text-600 line-height-16">
        {{ 'knbrInvoiceDetails.label.description' | cxTranslate }}
      </div>
      <div class="col-2 float-left pl-1 font-text-600 line-height-16">
        {{ 'knbrInvoiceDetails.label.quantity' | cxTranslate }}
      </div>
      <div class="col-2 float-left pl-1 font-text-600 line-height-16">
        {{ 'knbrInvoiceDetails.label.untPrice' | cxTranslate }}
      </div>
      <div class="col-1 float-right pl-1 font-text-600 pr-1 line-height-16">
        {{ 'knbrInvoiceDetails.label.amount' | cxTranslate }}
      </div>
    </div>
    <div *ngIf="this.invoiceData.note" class="customersearch-list-titles border-top float-left w-100 pl-3 pr-2 pt-3 d-none d-sm-none d-lg-block">
      <p class="font-sm-size mb-1">Note: {{this.invoiceData?.note}}</p>
    </div>
  </div>
  <div class="table-content float-left w-100 p-3 font-weight-normal w-100">
    <div class="d-flex py-2 text-muted content" *ngFor="let invoice of invoiceItems">
      <div class="col-2 pl-0">
        <span>
          {{ invoice.matNo }}
        </span>
      </div>
      <div class="col-4">
        <span>
          {{ invoice.description }}
        </span><br>
        <span>
          {{ 'knbrInvoiceDetails.label.customerMatNum' | cxTranslate }}: {{ invoice.customerMaterialNo }}
        </span>
      </div>
      <div class="col-2">
        <span>
          {{ this.separator(invoice.quantity) }} {{ invoice.quantityUOM }}
        </span>
      </div>
      <div class="col-2">
        <span>
          {{ this.formatNetPrice(invoice.netPriceValue) }}
        </span>
      </div>
      <div *ngIf="invoice.subTotal2 === ' - '" class="col-2">
        <span class="float-right">
          {{ invoice.subTotal2 }}
        </span>
      </div>
      <div *ngIf="invoice.subTotal2 && invoice.subTotal2 !== '-'" class="col-2">
        <span class="float-right">
          $ {{ this.separator(invoice.subTotal2) }}
        </span>
      </div>
    </div>
  </div>

  <div class="total-items">
    <div class="row justify-content-end">
      <div class="col-3 p-1">
        <span class="font-text-600">{{ 'knbrInvoiceDetails.label.materialTotal' | cxTranslate }}</span>
        <span *ngIf="this.invoiceData?.materialTotal" class="float-right value">$ {{ this.toFixed(this.invoiceData?.materialTotal) }}</span>
      </div>
    </div>
    <div *ngIf="this.invoiceData?.freight > 0" class="row justify-content-end">
      <div class="col-3 p-1">
        <span class="font-text-600 float">{{ 'knbrInvoiceDetails.label.frieghtCharges' | cxTranslate }}</span>&nbsp;
        <span class="p-0" placement="bottom" [ngbPopover]="popContent1" popoverTitle="Freight Charges Details">
          <i class="fas fa-plus-circle" aria-hidden="true"></i>
          <ng-template class="container" #popContent1>
            <span class="close-icon float-right"><i class="fas fa-times" aria-hidden="true"></i></span>
            <div *ngFor="let item of subFrieghtCharges">
              <span class="text-muted font-text-600">{{ item.name }}</span>
              <span class="float-right">$ {{ item.value }}</span>
            </div>
          </ng-template>
        </span>
        <span class="float-right value">$ {{ this.separator(this.invoiceData?.freight) }}</span>
      </div>
    </div>
    <div *ngIf="this.invoiceData?.tax > 0" class="row justify-content-end">
      <div class="col-3 p-1">
        <span class="font-text-600">{{ 'knbrInvoiceDetails.label.taxCharges' | cxTranslate }}</span>&nbsp;
        <span class="p-0" placement="bottom" [ngbPopover]="popContent2" popoverTitle="Tax Charges Details">
          <i class="fas fa-plus-circle" aria-hidden="true"></i>
          <ng-template class="container" #popContent2>
            <span class="close-icon float-right"><i class="fas fa-times" aria-hidden="true"></i></span>
            <div *ngFor="let item of subTaxCharges">
              <span class="text-muted font-text-600">{{ item.name }}</span>
              <span class="float-right">$ {{ item.value }}</span>
            </div>
          </ng-template>
        </span>
        <span class="float-right value">$ {{ this.separator(this.invoiceData?.tax) }}</span>
      </div>
    </div>
    <br>

    <div class="row justify-content-end">
      <div class="col-3 p-1">
        <span class="font-text-600">{{ 'knbrInvoiceDetails.label.total' | cxTranslate }}</span>
        <span  *ngIf="this.invoiceData?.summaryTotal !== 'null'" class="float-right value">$ {{ this.separator(this.invoiceData?.summaryTotal) }}</span>
      </div>
    </div>
  </div>
  <hr>

  <div class="text-muted">
    <h6><b>Instructions:</b></h6>
    <span>Amount of cash discount is computed by taking the delivered material price less freight and then multiplying by the discount rate as per your payment terms.</span>
    <br>
    <br>

    <div *ngIf="showRemittanceAddress">
      <h6>Make all remittances payable to the order of:</h6>
      <p>{{this.address}}</p>
    </div>

    <h6><b>For billing inquiries:</b></h6>
    <span>Phone 800-260-3101</span>
  </div>
  <div class="col-md-12 float-right d-flex justify-content-center mt-4">
    <button type="button" class="btn btn-action" (click)="goBack()">
      {{ 'knbrInvoiceDetails.label.backTo' | cxTranslate }}
    </button>
  </div>
</ng-container>
