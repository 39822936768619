<ng-container>
  <div class="col-lg-12 float-left p-3 border border-bottom-0 bg-light-gray">
    <div class="w-100">
      <div class="w-100 customersearch-title">
        {{ 'orderReturnsSearchForm.label.return' | cxTranslate }}
      </div>
    </div>
  </div>
  <div class="col-lg-12 float-left p-3 border">
    <form class="customersearch-forms login-forms p-0 w-100" [formGroup]="returnOrdersSearchForm"
      (ngSubmit)="submitForm()">
      <div class="col-12 p-0 float-left">
        <div class="col-6 pl-0 form-group float-left valid-to mt-3 mb-3">
          <div class="input-group">
            <input class="form-control w-100"
              [placeholder]="'orderReturnsSearchForm.label.fromReturnCreationDate' | cxTranslate" name="validFrom"
              ngbDatepicker #validFrom="ngbDatepicker" formControlName="startDate" [maxDate]="maxStartDate()" [readonly]="true"/>
            <span class="highlight"></span>
            <label class="text-muted">{{ 'orderReturnsSearchForm.label.fromReturnCreationDate' | cxTranslate }}</label>
            <div class="input-group-append">
              <button class="btn btn calendar p-0 h-auto w-auto" container="body" (click)="validFrom.toggle()"
                type="button">
                <i class="far fa-calendar-alt color-font"></i>
              </button>
            </div>
          </div>
        </div>
        <div class="col-6 pl-0 form-group float-left valid-to mt-3 mb-3">
          <div class="input-group">
            <input class="form-control w-100"
              [placeholder]="'orderReturnsSearchForm.label.toReturnCreationDate' | cxTranslate" name="validTo"
              [minDate]="returnOrdersSearchForm.get('startDate').value" ngbDatepicker #validTo="ngbDatepicker"
              formControlName="endDate" [maxDate]="maxEndDate()" [readonly]="true"/>
            <span class="highlight"></span>
            <label class="text-muted">{{ 'orderReturnsSearchForm.label.toReturnCreationDate' | cxTranslate }}</label>
            <div class="input-group-append">
              <button class="btn btn calendar p-0 h-auto w-auto" container="body" (click)="validTo.toggle()"
                type="button">
                <i class="far fa-calendar-alt color-font"></i>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 pl-0 float-left">
        <button type="submit" [disabled]="isSearchDisabled$ | async" class="btn btn-primary col-6">
          {{ 'button.search' | cxTranslate }}
        </button>
      </div>
    </form>
    <div class="col-lg-12 customer-search bg-white p-0 float-left"
      *ngIf="orderReturnListResult$ | async as orderReturnListResult">
      <div class="customersearch-list-table mt-3 float-left col-lg-12 p-0"
        *ngIf="orderReturnListResult && orderReturnListResult.length && !(isLoader$ | async)">
        <div class="customersearch-list-titles border-top border-bottom float-left w-100 pl-2 pr-2 pt-3 pb-3 d-none d-sm-none d-lg-block">
          <div class="col-md-2 padding-res-0 float-left font-text-600 pl-0 font-sm-size">
            {{ 'orderReturnResult.header.rma' | cxTranslate }}
          </div>
          <div class="col-md-3 float-left font-text-600 pl-0 font-sm-size">
            {{ 'orderReturnResult.widgetHeader.purchaseOrderNumber' | cxTranslate }}
          </div>
          <div class="col-md-4 float-left font-text-600 pl-0 font-sm-size">
            {{ 'orderReturnResult.header.customer' | cxTranslate }}
          </div>
          <div class="col-md-3 float-left font-text-600 pl-0 font-sm-size">
            {{ 'orderReturnResult.header.returnCreationDate' | cxTranslate }}
          </div>
          <!-- <div class="col-md-2 float-left font-text-600 pl-0 font-sm-size">
            {{ 'orderReturnResult.widgetHeader.orderValue' | cxTranslate }}
          </div> -->
        </div>
        <div class="bg-white customersearch-list-titles table-content border-bottom float-left w-100 p-2 mr-4 font-weight-normal d-flex align-items-center"
          *ngFor="let order of orderReturnListResult" (click)="redirectToDetail(order)">
          <div class="col-md-2 float-left title-wrap pl-0 font-sm-size color-font">
            <span class="res-titles"> {{ 'orderReturnResult.header.rma' | cxTranslate }}:</span>
            {{ order.rma | removeLeadingZeros }}
          </div>
          <div class="col-md-3 float-left title-wrap pl-0 font-sm-size color-font">
            <span class="res-titles"> {{ 'orderReturnResult.widgetHeader.purchaseOrderNumber' | cxTranslate }}:</span>
            {{ order.purchaseOrderNumber }}
          </div>
          <div class="col-md-4 float-left title-wrap pl-0 font-sm-size color-font">
            <span class="res-titles"> {{ 'orderReturnResult.header.accountNumber' | cxTranslate }}:</span>
            {{ order.accountNumber | removeLeadingZeros }} - {{ order.accountName }}
          </div>
          <div class="col-md-3 float-left title-wrap pl-0 font-sm-size color-font">
            <span class="res-titles">{{ 'orderReturnResult.header.returnCreationDate' | cxTranslate }}:</span>{{
            order.requestedDeliveryDate }}
          </div>
          <!-- <div class="col-md-2 float-left title-wrap pl-0 font-sm-size color-font">
            <span class="res-titles"> {{ 'orderReturnResult.widgetHeader.orderValue' | cxTranslate }}:</span>
            {{ order.totalPrice.formattedValue }}
          </div> -->
        </div>
      </div>
      <div class="alert-info p-3 rounded mt-3"
        *ngIf="orderReturnListResult && orderReturnListResult.length < 1 && !(isLoader$ | async)">
        {{ 'orderReturnResult.nodataMsg' | cxTranslate }}
      </div>
      <ng-container *ngIf="paginate$ | async as pagination">
        <div *ngIf="pagination.totalPages > 1" class="col-lg-12 p-0 float-left mt-3">
          <cx-pagination [pagination]="pagination" (viewPageEvent)="pageChange($event)"></cx-pagination>
        </div>
      </ng-container>

      <div class="col-lg-12 p-0 float-left mt-4"
        *ngIf="orderReturnListResult && orderReturnListResult.length && !(isLoader$ | async)">
        <a [routerLink]="{ cxRoute: 'returnOrders' } | cxUrl">{{ 'orderReturnResult.detailedSearch' | cxTranslate }}</a>
      </div>

      <div *ngIf="(isLoader$ | async) || !(orderReturnListResult$ | async)">
        <cx-spinner></cx-spinner>
      </div>
    </div>
  </div>
</ng-container>
