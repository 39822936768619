<div class="customersearch-list-table float-left col-lg-12 p-0 mt-3" *ngIf="factSheetData$ | async as factSheetData">

  <b *ngIf="factSheetData?.pagination?.totalPages === 0">
    Products and pricing are displayed for buyers extended to {{this.brandName$ | async}}. Please <a [routerLink]="'../../contact-us' | cxUrl"><u>Ask Us</u></a> if there are questions or concerns.
  </b>
  <div class="customersearch-list-titles border-top float-left w-100 p-3 d-none d-sm-none d-lg-block" *ngIf="factSheetData?.priceSheetItems && factSheetData?.priceSheetItems.length" id="navbar">
    <div class="col-md-1 padding-res-0 float-left font-text-400 pl-0 font-size text-muted">
      {{ 'factSheetMaterial.header.code' | cxTranslate }}
    </div>
    <div class="col-md-2 padding-res-0 float-left font-text-400 pl-0 font-sm-size text-muted">
      {{ 'factSheetMaterial.header.description' | cxTranslate }}
    </div>
    <div class="col-md-1 padding-res-0 float-left font-text-400 pl-0 font-sm-size text-muted">
      {{ 'factSheetMaterial.header.productFamily' | cxTranslate }}
    </div>
    <div class="col-md-1 padding-res-0 float-left font-text-400 pl-0 font-sm-size text-muted">
      {{ 'factSheetMaterial.header.haz' | cxTranslate }}
    </div>
    <div class="col-md-1 padding-res-0 float-left font-text-400 pl-0 font-sm-size text-muted">
      {{ 'factSheetMaterial.header.stockingLocation' | cxTranslate }}
    </div>
    <div class="col-md-1 padding-res-0 float-left font-text-400 pl-0 font-sm-size text-muted">
      {{ 'factSheetMaterial.header.packageUnit' | cxTranslate }}
    </div>
    <div class="col-md-1 padding-res-0 float-left font-text-400 pl-0 font-sm-size text-muted">
      {{ 'factSheetMaterial.header.packageQty' | cxTranslate }}
    </div>
    <div class="col-md-1 padding-res-0 float-left font-text-400 pl-0 font-sm-size text-muted">
      {{ 'factSheetMaterial.header.weight' | cxTranslate }}
    </div>
    <div class="col-md-1 padding-res-0 float-left font-text-400 pl-0 font-sm-size text-muted">
      {{ 'factSheetMaterial.header.listPrice' | cxTranslate }}
    </div>
    <div class="col-md-1 padding-res-0 float-left font-text-400 pl-0 font-size text-muted">
      {{ 'factSheetMaterial.header.netPrice' | cxTranslate }}
    </div>
    <!-- <div class="col-md-1 padding-res-0 float-left font-text-400 pl-0 font-size text-muted">
      {{ 'factSheetMaterial.header.priceQty' | cxTranslate }}
    </div> -->
    <div class="col-md-1 padding-res-0 float-left font-text-400 pl-0 font-size text-muted">
      {{ 'factSheetMaterial.header.perPallet' | cxTranslate }}
    </div>
  </div>

  <div
    class="bg-white customersearch-list-titles table-content border-top float-left w-100 pt-2 pb-2 pl-3 pr-3 font-weight-normal d-flex align-items-center"
    *ngFor="let materials of factSheetData?.priceSheetItems"
  >
    <div class="col-md-1 float-left title-wrap pl-0 font-size color-font">
      <span class="res-titles"> {{ 'factSheetMaterial.header.material' | cxTranslate }}</span>
      {{ materials?.product }}
    </div>
    <div class="col-md-2 float-left title-wrap pl-0 font-sm-size color-font" [innerHTML]="materials?.productName">
      <span class="res-titles"> {{ 'factSheetMaterial.header.description' | cxTranslate }}</span>
    </div>
    <div class="col-md-1 float-left title-wrap pl-0 font-sm-size color-font" [innerHTML]="materials?.productFamily">
      <span class="res-titles"> {{ 'factSheetMaterial.header.productFamily' | cxTranslate }}</span>
    </div>
    <div class="col-md-1 float-left title-wrap pl-0 font-sm-size color-font" [innerHTML]="materials?.hazMat">
      <span class="res-titles"> {{ 'factSheetMaterial.header.haz' | cxTranslate }}</span>
    </div>
    <div class="col-md-1 float-left title-wrap pl-0 font-sm-size color-font" [innerHTML]="materials?.stockingLoc">
      <span class="res-titles"> {{ 'factSheetMaterial.header.stockingLocation' | cxTranslate }}</span>
    </div>
    <div class="col-md-1 float-left title-wrap pl-0 font-sm-size color-font" [innerHTML]="materials?.packageUnit">
      <span class="res-titles"> {{ 'factSheetMaterial.header.packageUnit' | cxTranslate }}</span>
    </div>
    <div class="col-md-1 float-left title-wrap pl-0 font-sm-size color-font" [innerHTML]="materials?.packageQuantity">
      <span class="res-titles"> {{ 'factSheetMaterial.header.packageQty' | cxTranslate }}</span>
    </div>
    <div class="col-md-1 float-left title-wrap pl-0 font-sm-size color-font" [innerHTML]="materials?.packageUnitWeight">
      <span class="res-titles"> {{ 'factSheetMaterial.header.weight' | cxTranslate }}</span>
    </div>
    <div
      class="col-md-1 float-left title-wrap pl-0 font-sm-size color-font"
      [innerHTML]="materials?.listPrice"
    >
      <span class="res-titles"> {{ 'factSheetMaterial.header.listPriceFormatted' | cxTranslate }}</span>
    </div>
    <div class="col-md-1 float-left title-wrap pl-0 font-size color-font">
      <span class="res-titles"> {{ 'factSheetMaterial.header.netPrice' | cxTranslate }}</span>
      {{ materials?.netPrice }}
    </div>
    <!-- <div class="col-md-1 float-left title-wrap pl-0 font-size color-font">
      <span class="res-titles"> {{ 'factSheetMaterial.header.priceQty' | cxTranslate }}</span>
      {{ materials?.pricePerQty }}
    </div> -->
    <div class="col-md-1 float-left title-wrap pl-0 font-size color-font">
      <span class="res-titles"> {{ 'factSheetMaterial.header.perPallet' | cxTranslate }}</span>
      {{ materials?.packsPerPal }}
    </div>
  </div>
  <div class="col-lg-12 d-flex">
    <ng-container *ngIf="paginate$ | async as paginate">
      <div class="col-lg-6 px-0 mt-3 font-text-400 font-md-size text-muted">
        {{'factSheetMaterial.showingDetail' | cxTranslate : { current: (factSheetData?.priceSheetItems?.length), total: factSheetData?.pagination?.totalResults } }}
      </div>
      <div class="col-lg-6 w-100 mt-3">
        <div *ngIf="paginate.currentPage < paginate.totalPages - 1 && !(isLoader$ | async)"
          (click)="loadNextPage(paginate.currentPage)" class="btn btn-loadmore mt-0 mr-3">
          {{ 'productList.showMore' | cxTranslate }}
        </div>
      </div>
    </ng-container>
  </div>
</div>
