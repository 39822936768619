import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ComponentRef, OnInit, ViewContainerRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { GlobalMessageService, GlobalMessageType, WindowRef } from '@spartacus/core';
import { LaunchDialogService, LAUNCH_CALLER } from '@spartacus/storefront';
import { Observable, Subscription } from 'rxjs';
import { KnBrConfirmationDialogService } from 'src/app/common/kn-br-confirm-dialog/kn-br-confirm-dialog.service';
import { CustomerData } from 'src/app/models/customer.model';
import { KnBrFactSheetData, knBrFactSheet } from 'src/app/models/kn-br-fact-sheet.model';
import { Statuses } from 'src/app/models/kn-br-quote.model';
import { KnBrCommonService } from 'src/app/services/kn-br-common.service';
import { KnBrProductSalesOrgService } from 'src/app/services/kn-br-product-sales-org.service';
import { KnBrCustomerContextService } from 'src/app/store/kn-br-customer-context/kn-br-customer-context.service';
import { KnBrFactSheetService } from 'src/app/store/kn-br-fact-sheet/kn-br-fact-sheet.service';
import { KN_BR_CUSTOMER_CURRENT } from 'src/constants/api.endpoints.constant';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { KnBrPriceBookPopUpComponent } from '../../kn-br-price-book-pop-up/kn-br-price-book-pop-up.component';

@Component({
  selector: 'knbr-kn-br-fact-sheet-header',
  templateUrl: './kn-br-fact-sheet-header.component.html',
  styleUrls: ['./kn-br-fact-sheet-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KnBrFactSheetHeaderComponent implements OnInit {
  factSheetData$: Observable<KnBrFactSheetData> = this.knBrFactSheetService.factSheetData$;
  customerContextData$: Observable<CustomerData> = this.knBrCustomerContextService.get$;
  brandList$: Observable<Statuses[]> = this.knBrCommonService.loadBrandList$();
  loader$: Observable<boolean> = this.knBrFactSheetService.loading$;
  showSpinner: void | Observable<ComponentRef<any>>;
  subscription = new Subscription();
  customerNumber = '';
  customerName = '';
  objectKeys = Object.keys;
  activeBrand = '';
  downloadProgress = false;
  showAllOrderEmails = true;
  showAllInvoiceEmails = true;
  EMAILS_SHOW_LIMIT = 4;
  currentPage: any = 0;
  brandName: any;
  isDisabled = false;
  emailSent = false;
  brandDataWithImages = [];
  selectedBrands = [];
  categories = [];
  selectedCategories = [];
  priceBookClicked = false;
  modalRef: any;
  sortedBrandKeys=[]
  selectedBrandNamesList= [];
  country: any;
  factSheetData;


  constructor(
    protected knBrFactSheetService: KnBrFactSheetService,
    protected launchDialogService: LaunchDialogService,
    private knBrCommonService: KnBrCommonService,
    private knBrCustomerContextService: KnBrCustomerContextService,
    protected http: HttpClient,
    protected vcr: ViewContainerRef,
    public cd: ChangeDetectorRef,
    private winRef: WindowRef,
    private globalMessageService: GlobalMessageService,
    protected knBrProductSalesOrgService: KnBrProductSalesOrgService,
    private _sanitizer: DomSanitizer,
    protected confirmationDialogService: KnBrConfirmationDialogService,
    protected modalService?: NgbModal
  ) {}

  ngOnInit(): void {
    this.brandList$.subscribe((brand) => {
      if (brand) {
        const keys = this.objectKeys(brand);
        keys.map((eachKey) => {
          this.getSalesOrg(eachKey);
        });
        if (keys.length === 1) {
          const initialReq: knBrFactSheet = {
            param: '',
            currentPage: 0,
            categories: [],
          }
          this.knBrFactSheetService.getFactSheetData(initialReq);
          // this.fetchFactSheet(keys[0]);
        } else {
          this.winRef.localStorage.removeItem('brandId');
          this.winRef.localStorage.removeItem('pageNumber');
          this.knBrFactSheetService.getFactSheetData(null);
        }
      }
    });

    this.customerContextData$.subscribe((value) => {
      this.customerNumber = value?.customerNumber ? value?.customerNumber : KN_BR_CUSTOMER_CURRENT;
      this.customerName = value?.customerName;
      this.knBrCommonService.getBrandList(this.customerNumber);
    });
    this.subscription.add(
      this.loader$.subscribe((isloading) => {
        this.onSpinnerLoading(isloading);
      })
    );

    this.subscription.add(
      this.factSheetData$.subscribe(res => {
        this.factSheetData = res;
      })
    );

    this.country = JSON.parse(this.winRef.localStorage.getItem('kn-br-customer-context')).country;
  }

  sortBrands(brandData){
    const res = Object.keys(brandData).map(key => ({ name: brandData[key], value: key  }) ) ;
    const sortBrand = this.knBrCommonService.alphabeticalSort(res, 'name');
    const brandKeys = sortBrand.map((eachBrndObj:any) => {
      return eachBrndObj.value
    })
    this.sortedBrandKeys = brandKeys;
    return brandKeys;
  }

  formatPhone(value) {
    const trim = value?.substring(2);
    const cleaned = ('' + trim).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    } else {
      const removeSpaces = value?.replace(/\s/g, '');
      const trim = removeSpaces?.substring(1);
      const match = trim?.match(/^(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        return '(' + match[1] + ') ' + match[2] + '-' + match[3];
      }
    }
  }

  fetchFactSheet(brand: string) {
    this.winRef.localStorage.removeItem('brandId');
    this.winRef.localStorage.removeItem('pageNumber');
    this.activeBrand = brand;
    const req: knBrFactSheet = {
      param: brand,
      currentPage: this.currentPage,
      categories: [...this.selectedCategories]
    };
    this.activeBrand ? req : (req.param = brand);
    if (this.winRef.localStorage) {
      this.winRef.localStorage.setItem('brandId', this.activeBrand);
      this.winRef.localStorage.setItem('pageNumber', this.currentPage);
      this.winRef.localStorage.setItem('selectedCategories', JSON.stringify(this.selectedCategories));
    }
    this.findBrandName(brand);
    this.knBrFactSheetService.getFactSheetData(req);
  }

  getPriceBookDetail() {
    if (this.selectedBrands.length) {
      let firstBrand = this.selectedBrands[0];
      const sortedSelectioneys = this.sortedBrandKeys.filter((eachKey) => {
        if(this.selectedBrands.includes(eachKey)) {
          return eachKey;
        }
    });
    firstBrand = sortedSelectioneys[0];
      this.priceBookClicked = true;
      this.fetchFactSheet(firstBrand);
    }
    this.getSelectedBrandNamesList();
  }

  brandChangeOnTable(brand) {
    const isCanada = window.location.href.includes("carlisle-customer-ca");
    if(isCanada) {
      this.fetchFactSheet(brand.idCA);
    }else {
      this.fetchFactSheet(brand.id);
    }

  }
  getSelectedBrandNamesList() {
    const sortedSelectioneys = this.sortedBrandKeys.filter((eachKey) => {
        if(this.selectedBrands.includes(eachKey)) {
          return eachKey;
        }
    });

    const sortedSelectionObjects = []
    if(this.isCanada()) {
      sortedSelectioneys.forEach((eachbrd) => {
       this.brandDataWithImages.forEach((imgData) => {
           if(imgData.idCA === eachbrd) {
             sortedSelectionObjects.push(imgData);
           }
       })
     } );
    } else {
      sortedSelectioneys.forEach((eachbrd) => {
        this.brandDataWithImages.forEach((imgData) => {
            if(imgData.id === eachbrd) {
              sortedSelectionObjects.push(imgData);
            }
        })
      } );

    }

    const unique = sortedSelectionObjects.filter((obj, index) => {
      return index === sortedSelectionObjects.findIndex(o => obj.id === o.id);
    });
    this.selectedBrandNamesList = unique;
    return this.selectedBrandNamesList;
  }

  isCanada() {
    return window.location.href.includes("carlisle-sales-ca") ? true : false;
  }

  onBrandChange(brand: string, isChecked: boolean) {
    if (isChecked) {
      this.selectedBrands.push(brand);
    } else {
      let index = this.selectedBrands.indexOf(brand);
      this.selectedBrands.splice(index, 1);
    }
    this.cd.detectChanges();
    if(this.priceBookClicked) {
      this.openPopup('Confirm Filter Change', 'Are you sure you want to update the selection criteria?').then(
        (result) => {
          if (result) {
            this.priceBookClicked = false;
            this.updateCategoriesFromBrand();
          } else {
            if (isChecked) {
              let index = this.selectedBrands.indexOf(brand);
              this.selectedBrands.splice(index, 1);
              this.cd.detectChanges();
            } else {
              this.selectedBrands.push(brand);
              this.cd.detectChanges();
            }
          }
        }
      );
    } else {
      this.updateCategoriesFromBrand();
    }
  }

  updateCategoriesFromBrand() {
    this.selectedCategories = [];
    const categoriesPayload = this.selectedBrands.join(',');
    this.knBrCommonService.getCategoryList(categoriesPayload).subscribe((resp) => {
      const catData = Object.keys(resp).map((eachKey) => {
        return resp[eachKey];
      });
      this.categories = catData;
      this.cd.detectChanges();
    });
  }

  onCategoryChange(category: string, isChecked: boolean) {
    if (isChecked ) {
      this.selectedCategories.push(category);
    } else {
      let index = this.selectedCategories.indexOf(category);
      this.selectedCategories.splice(index, 1);
    }
    this.cd.detectChanges();
    if(this.priceBookClicked) {
      this.openPopup('Confirm Filter Change', 'Are you sure you want to update the selection criteria?').then(
        (result) => {
          if (result) {
            this.priceBookClicked = false;
            this.cd.detectChanges();
          } else {
            if (isChecked) {
              let index = this.selectedCategories.indexOf(category);
              this.selectedCategories.splice(index, 1);
              this.cd.detectChanges();
            } else {
              this.selectedCategories.push(category);
              this.cd.detectChanges();
            }
          }
        }
      );
    }
  }

  openPopup(header, body) {
    this.downloadProgress = false;
    let modalInstance: any;
    this.modalRef = this.modalService.open(KnBrPriceBookPopUpComponent, {
      centered: true,
      size: 'md',
    });
    modalInstance = this.modalRef.componentInstance;
    modalInstance.header = header;
    modalInstance.message = body;
    modalInstance.isImage = false;

    return this.modalRef.result;
  }

  findBrandName(brand) {
    this.brandList$.subscribe((res) => {
      const asArray = Object.entries(res);
      const filtered = asArray.filter(([key]) => key == brand);
      this.brandName = filtered[0][1];
      this.knBrFactSheetService.setBrandName(this.brandName);
    });
  }

  getSalesOrg(salesOrg: string) {
    const _salesOrg = this.knBrProductSalesOrgService.getSalesOrgDetails(salesOrg);
    if (_salesOrg) {
      this.brandDataWithImages.push(_salesOrg);
    }
  }

  getImage(brandId) {
    if (this.brandDataWithImages) {
      let imageToFetch = this.brandDataWithImages.filter((eachbrdObj) => eachbrdObj.id === brandId)[0];
      if(imageToFetch) {
        return imageToFetch ? this._sanitizer.bypassSecurityTrustHtml(`${imageToFetch.image}`) : '';
      } else {
       imageToFetch = this.brandDataWithImages.filter((eachbrdObj) => eachbrdObj.idCA === brandId)[0];
       return imageToFetch ? this._sanitizer.bypassSecurityTrustHtml(`${imageToFetch.image}`) : '';
      }
    }
  }

  sendPriceEmail() {
    this.isDisabled = true;
    const payload = {
      brandId: this.selectedBrands.join(','),
      brandName: this.getBrandNames(),
      soldTo: this.customerNumber,
      soldToName: this.customerName,
      country: this.country,
      categories: this.selectedCategories,
      header: this.factSheetData.header
    };
    this.knBrCommonService.sendPriceEmail(this.customerNumber, payload).subscribe((res: any) => {
      if (res) {
        this.emailSent = res?.sendEmail;
      }
    });
    setTimeout(() => {
      const successMsg = `We've received your request for a ${payload.brandName} price list. You can expect to receive the price list in your account email within 2 hours.`;
      this.globalMessageService.add(successMsg, GlobalMessageType.MSG_TYPE_CONFIRMATION);
      this.enableButton();
    }, 5000);
  }

  getBrandNames() {
    const someData = []
    this.selectedBrands.forEach(item => {
      this.brandDataWithImages.map(obj => {
        if (this.isCanada()) {
          if (obj.idCA === item) {
            someData.push(obj.name)
          }
        } else {
          if (obj.id === item) {
            someData.push(obj.name)
          }
        }
      })

    })
    return someData.join(',');
  }

  enableButton() {
    this.isDisabled = false;
    this.cd.detectChanges();
  }

  onSpinnerLoading(isLoading) {
    if (isLoading) {
      this.showSpinner = this.launchDialogService.launch(LAUNCH_CALLER.PLACE_ORDER_SPINNER, this.vcr);
    } else {
      if (this.showSpinner) {
        this.showSpinner
          .subscribe((component) => {
            this.launchDialogService.clear(LAUNCH_CALLER.PLACE_ORDER_SPINNER);
            component.destroy();
          })
          .unsubscribe();
      }
    }
  }
  splitEmails(emails: string) {
    if (!emails) return { length: 0, values: [] };
    const emailsArray = emails.split('|');
    return { length: emailsArray.length, values: emailsArray };
  }

  arrayToObject(arr: { key: string; value: string }[]) {
    if (!Array.isArray(arr)) return {};

    return arr.reduce((result, item) => {
      var key = item.key;
      result[key] = item.value;
      return result;
    }, {});
  }
}
