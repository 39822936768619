import { KnBrCommonService } from './../services/kn-br-common.service';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';

import { AuthService, GlobalMessageService, WindowRef, GlobalMessageType } from '@spartacus/core';
import { LoginFormComponent, LoginFormComponentService } from '@spartacus/user/account/components';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'kn-br-login-form',
  templateUrl: './kn-br-login-form.component.html',
  styleUrls: ['./kn-br-login-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KnBrLoginFormComponent extends LoginFormComponent {
  errorMessage: string = 'Bad credentials. Please login again';
  loginInProgress = false;
  baseURL = environment.BASEURL;
  constructor(
    protected auth: AuthService,
    protected globalMessageService: GlobalMessageService,
    protected fb: UntypedFormBuilder,
    protected winRef: WindowRef,
    protected loginFormComponentService: LoginFormComponentService,
    protected knBrCommonService: KnBrCommonService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    super(loginFormComponentService);
    if (!this.baseURL.includes('d1')) {
      this.redirectToSSO();
    }
  }
  ngOnInit(): void {
    // super.ngOnInit();
    if (this.winRef) {
      this.winRef.document.querySelector('kn-br-login-form').classList.add('knbr-user-form');
    }
  }

  checkIsValidUser() {
    if (this.form.valid) {
      this.loginInProgress = true;

      const { userId, password } = this.form.controls;
      this.knBrCommonService.checkIsValidUser(userId.value).subscribe(
        (response: any) => {
          if (response?.response === 'Authorized') {
            this.loginFormComponentService.login();
          } else {
            this.globalMessageService.add(response, GlobalMessageType.MSG_TYPE_ERROR);
          }
          this.loginInProgress = false;
          this.changeDetectorRef.detectChanges();
        },
        (error) => {
          const errorResponse = error.error;
          if (errorResponse && errorResponse.errors && errorResponse.errors.length > 0) {
            // this.globalMessageService.add(errorResponse.errors[0].message, GlobalMessageType.MSG_TYPE_ERROR);
            this.globalMessageService.add(this.errorMessage, GlobalMessageType.MSG_TYPE_ERROR)
          }
          this.loginInProgress = false;
          this.changeDetectorRef.detectChanges();
        }
      );
    } else {
      this.form.markAllAsTouched();
    }
  }
  redirectToSSO() {
    window.open(environment.SSO, '_self')
  }
}
