import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalMessageService, GlobalMessageType, OccEndpointsService, RoutingService } from '@spartacus/core';
import { FileSaverService } from 'ngx-filesaver';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  KN_BR_CUSTOMER_CURRENT,
  KNBR_DRAFT_ORDER_DOWNLOAD,
  KNBR_DRAFT_ORDER_IMAGE_DOWNLOAD,
  KNBR_DRAFT_ORDER_CSV_DOWNLOAD,
  KNBR_DRAFT_ORDER_DETAIL_CSV_DOWNLOAD,
} from 'src/constants/api.endpoints.constant';

import { KnBrCustomerContextService } from '../store/kn-br-customer-context/kn-br-customer-context.service';
import {
  KN_BR_CARTS_COUNTER,
  KN_BR_CARTS_VALUE,
  KN_BR_QUOTES_COUNTER,
  KN_BR_QUOTES_VALUE,
} from './../../constants/api.endpoints.constant';
import { CartQuoteSummary } from './../models/cart-quote-summary.model';

@Injectable({
  providedIn: 'root',
})
export class KnBrCartQuoteSummaryService {
  cartsCounterResult$: BehaviorSubject<CartQuoteSummary[]> = new BehaviorSubject(null);
  cartsValueResult$: BehaviorSubject<CartQuoteSummary[]> = new BehaviorSubject(null);
  quotesCounterResult$: BehaviorSubject<CartQuoteSummary[]> = new BehaviorSubject(null);
  quotesValueResult$: BehaviorSubject<CartQuoteSummary[]> = new BehaviorSubject(null);
  downloadFlagResult$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  draftOrderListSelectedProductResult$: BehaviorSubject<string[]> = new BehaviorSubject([]);
  customerId: string;
  constructor(
    private endPointService: OccEndpointsService,
    private httpClient: HttpClient,
    private globalMessageService: GlobalMessageService,
    private routingService: RoutingService,
    private fileSaver: FileSaverService,
    protected knBrCustomerContextService: KnBrCustomerContextService
  ) {
    this.knBrCustomerContextService.getActiveCustomerNumber().subscribe((response) => {
      this.customerId = response ? response : KN_BR_CUSTOMER_CURRENT;
    });
  }

  getCartsCounter(customerId: string) {
    const CUSTOMER_NUMBER = customerId ? customerId : KN_BR_CUSTOMER_CURRENT;

    this.httpClient.get(this.endPointService.buildUrl(KN_BR_CARTS_COUNTER(CUSTOMER_NUMBER))).subscribe((response) => {
      this.cartsCounterResult$.next(response as CartQuoteSummary[]);
    });
  }

  loadCartsCounter(): Observable<CartQuoteSummary[]> {
    return this.cartsCounterResult$.asObservable();
  }

  getCartsValue(customerId: string) {
    const CUSTOMER_NUMBER = customerId ? customerId : KN_BR_CUSTOMER_CURRENT;
    this.httpClient

      .get<CartQuoteSummary[]>(this.endPointService.buildUrl(KN_BR_CARTS_VALUE(CUSTOMER_NUMBER)))
      .subscribe((response) => {
        this.cartsValueResult$.next(response as CartQuoteSummary[]);
      });
  }

  loadCartsValue(): Observable<CartQuoteSummary[]> {
    return this.cartsValueResult$.asObservable();
  }

  getQuotesCounter(customerId: string) {
    const CUSTOMER_NUMBER = customerId ? customerId : KN_BR_CUSTOMER_CURRENT;
    this.httpClient

      .get<CartQuoteSummary[]>(this.endPointService.buildUrl(KN_BR_QUOTES_COUNTER(CUSTOMER_NUMBER)))
      .subscribe((response) => {
        this.quotesCounterResult$.next(response as CartQuoteSummary[]);
      });
  }

  loadQuotesCounter(): Observable<CartQuoteSummary[]> {
    return this.quotesCounterResult$.asObservable();
  }

  getQuotesValue(customerId: string) {
    const CUSTOMER_NUMBER = customerId ? customerId : KN_BR_CUSTOMER_CURRENT;
    this.httpClient

      .get<CartQuoteSummary[]>(this.endPointService.buildUrl(KN_BR_QUOTES_VALUE(CUSTOMER_NUMBER)))
      .subscribe((response) => {
        this.quotesValueResult$.next(response as CartQuoteSummary[]);
      });
  }

  loadQuotesValue(): Observable<CartQuoteSummary[]> {
    return this.quotesValueResult$.asObservable();
  }

  downloadDraftOrder(cartId, multiple = false) {
    this.globalMessageService.add({ key: 'knBrMessages.downloadInProgress' }, GlobalMessageType.MSG_TYPE_INFO);
    const HTTPOptions = {
      headers: new HttpHeaders({ Accept: 'application/json; charset=UTF-8' }),
      observe: 'response' as 'body', // to display the full response & as 'body' for type cast
      responseType: 'blob' as 'json',
    };
    this.httpClient
      .get(
        this.endPointService.buildUrl(KNBR_DRAFT_ORDER_DOWNLOAD, {
          urlParams: {
            customerId: this.customerId,
            cartId,
          },
        }),
        HTTPOptions
      )
      .subscribe(
        (response: HttpResponse<any>) => {
          if (multiple) {
            this.fileSaver.save(response.body as any, 'Carts.zip');
          } else {
            let fileName: string;
            if (response.headers) {
              fileName = this.getFileNameFromResponse(response);
            }
            if (!fileName) {
              fileName = cartId + '.pdf';
            }
            this.fileSaver.save(response.body as any, fileName);
          }
          this.globalMessageService.add(
            { key: 'knBrMessages.downloadIsCompleted' },
            GlobalMessageType.MSG_TYPE_CONFIRMATION
          );
        },
        (error) => {
          this.globalMessageService.add({ key: 'knBrMessages.downloadIsFailed' }, GlobalMessageType.MSG_TYPE_ERROR);
        }
      );
  }

  getFileNameFromResponse(response: HttpResponse<any>) {
    const contentDisposition = response.headers.get('Content-Disposition');
    if (contentDisposition) {
      const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = fileNameRegex.exec(contentDisposition);
      if (matches != null && matches[1]) {
        return matches[1].replace(/['"]/g, '');
      }
    }
  }

  setDownloadFlag(downloadFlag: boolean) {
    this.downloadFlagResult$.next(downloadFlag);
  }

  loadDownloadFlag$(): Observable<boolean> {
    return this.downloadFlagResult$.asObservable();
  }
  setDraftOrderListSelectedProducts(products: string[]) {
    this.draftOrderListSelectedProductResult$.next(products);
  }
  loadDraftOrderListSelectedProducts$(): Observable<string[]> {
    return this.draftOrderListSelectedProductResult$.asObservable();
  }

  downloadDraftOrderImages(cartId) {
    this.globalMessageService.add({ key: 'knBrMessages.downloadInProgress' }, GlobalMessageType.MSG_TYPE_INFO);
    const HTTPOptions = {
      headers: new HttpHeaders({ Accept: 'application/zip; charset=UTF-8' }),
      observe: 'response' as 'body', // to display the full response & as 'body' for type cast
      responseType: 'blob' as 'json',
    };
    this.httpClient
      .get(
        this.endPointService.buildUrl(KNBR_DRAFT_ORDER_IMAGE_DOWNLOAD, {
          urlParams: {
            customerId: this.customerId,
            cartId,
          },
        }),
        HTTPOptions
      )
      .subscribe(
        (response: HttpResponse<any>) => {
          let fileName: string;
          if (response.headers) {
            fileName = this.getFileNameFromResponse(response);
          }
          if (!fileName) {
            fileName = cartId + '_Images.zip';
          }
          this.fileSaver.save(response.body as any, fileName);
          this.globalMessageService.add(
            { key: 'knBrMessages.downloadIsCompleted' },
            GlobalMessageType.MSG_TYPE_CONFIRMATION
          );
        },
        (error) => {
          this.globalMessageService.add({ key: 'knBrMessages.downloadIsFailed' }, GlobalMessageType.MSG_TYPE_ERROR);
        }
      );
  }

  downloadDraftOrderCSV(cartId) {
    this.globalMessageService.add({ key: 'knBrMessages.downloadInProgress' }, GlobalMessageType.MSG_TYPE_INFO);
    const HTTPOptions = {
      headers: new HttpHeaders({ Accept: 'application; charset=UTF-8' }),
      observe: 'response' as 'body', // to display the full response & as 'body' for type cast
      responseType: 'blob' as 'json',
    };
    let url;
    if (cartId) {
      url = this.endPointService.buildUrl(KNBR_DRAFT_ORDER_DETAIL_CSV_DOWNLOAD, {
        urlParams: {
          customerId: this.customerId,
          cartId,
        },
      });
    } else {
      url = this.endPointService.buildUrl(KNBR_DRAFT_ORDER_CSV_DOWNLOAD, {
        urlParams: {
          customerId: this.customerId,
          // cartId,
        },
      });
    }
    this.httpClient.get(url, HTTPOptions).subscribe(
      (response: HttpResponse<any>) => {
        let fileName: string;
        if (response.headers) {
          fileName = this.getFileNameFromResponse(response);
        }
        if (!fileName) {
          fileName = cartId + '.xlsx';
        }
        this.fileSaver.save(response.body as any, fileName);
        this.globalMessageService.add(
          { key: 'knBrMessages.downloadIsCompleted' },
          GlobalMessageType.MSG_TYPE_CONFIRMATION
        );
      },
      (error) => {
        this.globalMessageService.add({ key: 'knBrMessages.downloadIsFailed' }, GlobalMessageType.MSG_TYPE_ERROR);
      }
    );
  }
}
