import { Routes } from '@angular/router';
import { PageLayoutComponent, CmsPageGuard } from '@spartacus/storefront';
import { KnBrLogoutComponent } from '../kn-br-logout/kn-br-logout.component';
import { KnBrRedirectGuard } from '../shared/guards/kn-br-redirect.guard';
import { environment } from 'src/environments/environment';
import { KnbrSsoComponent } from '../knbr-sso/knbr-sso.component';

export const KnBrCustomRoutes: Routes = [
  {
    path: 'customer-360/order/:orderCode',
    component: PageLayoutComponent,
    data: { pageLabel: '/customer-360/order' },
    canActivate: [CmsPageGuard],
  },
  {
    path: 'customer-360/return-request/:returnCode',
    component: PageLayoutComponent,
    data: { pageLabel: '/customer-360/return-request' },
    canActivate: [CmsPageGuard],
  },
  {
    path: 'customer-360/order/return/:orderCode/:returnType',
    component: PageLayoutComponent,
    data: { pageLabel: '/customer-360/order/return' },
    canActivate: [CmsPageGuard],
  },
  {
    path: 'customer-360/order/return/confirmation/:orderCode/:type/:rma',
    component: PageLayoutComponent,
    data: { pageLabel: '/customer-360/order/return/confirmation' },
    canActivate: [CmsPageGuard],
  },
  {
    path: 'customer-360/return-request/:returnCode',
    component: PageLayoutComponent,
    data: { pageLabel: '/customer-360/return-request' },
    canActivate: [CmsPageGuard],
  },
  {
    path: 'customer-360/order/:orderCode',
    component: PageLayoutComponent,
    data: { pageLabel: '/customer-360/order' },
    canActivate: [CmsPageGuard],
  },
  {
    path: 'customer-360/order/edit/confirmation/:orderCode/:type/:orderNumber',
    component: PageLayoutComponent,
    data: { pageLabel: '/customer-360/order/edit/confirmation' },
    canActivate: [CmsPageGuard],
  },
  {
    path: 'customer-360/order/cancel/confirmation/:orderCode/:type',
    component: PageLayoutComponent,
    data: { pageLabel: '/customer-360/order/cancel/confirmation' },
    canActivate: [CmsPageGuard],
  },
  {
    path: 'customer-360/invoice',
    component: PageLayoutComponent,
    data: { pageLabel: 'customer-360/invoice' },
    canActivate: [CmsPageGuard],
  },
  {
    path: 'logout',
    component: KnBrLogoutComponent,
  },
  // comment for local login
  // {
  //   path: 'login',
  //   component: KnbrSsoComponent,
  //    data: {
  //      externalUrl: environment.externalSSO
  //    },
  //   canActivate: [KnBrRedirectGuard]
  // }
];
