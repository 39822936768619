<div *ngIf="address" class="col-12 float-left mt-2 p-0">
  <label class="color-font font-text-600 mb-1">{{ this.addressLabel }}</label>
  <p class="float-left text-muted mb-2">
    <span class="w-100 float-left">{{address.firstName}}</span>
    <span class="w-100 float-left">{{address.street1}}</span>
    <span class="w-100 float-left">{{address.city}}</span>
    <span class="w-100 float-left">{{address.region}}</span>
    <span class="w-100 float-left">{{this.formatZipcode(address.postalCode)}}</span>
  </p>
</div>
