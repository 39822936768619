<div
  class="
    bg-white
    customersearch-list-titles
    border-top
    float-left
    w-100
    p-3
    font-weight-normal
    d-flex
    align-items-center
    product-list-content
  "
  (click)="goToDetail($event)"
  tabindex="-1"
>
  <div class="col-md-4 float-left d-flex align-items-center p-0 product-list-img">
    <div
      class="remember-me custom-control float-left d-flex justify-content-start cursor-pointer mr-3 pl-0"
      *ngIf="editable"
    >

    </div>
    <cx-media [container]="product.images?.PRIMARY" [format]="'thumbnail'" [alt]="product.summary"></cx-media>
    <div class=" p-0 d-flex product-name mb-2 justify-content-center">
      <div [innerHTML]="getSVGImage(salesOrg.imgUrl)"  ></div>
     </div>
    <div class="col-lg-8 float-left" [innerHtml]="product.nameHtml"></div>
  </div>
  <div class="col-md-1 text-center float-left title-wrap">{{ product.code }}</div>
  <div class="col-md-2 text-center float-left title-wrap">{{ product.price?.formatedNetPrice }}</div>
  <div class="col-md-2 text-center float-left title-wrap">{{ product.price?.formattedValue }}</div>
  <div class="col-md-1 text-center float-left title-wrap">{{ product?.uom }}</div>
  <div class="col-md-1 text-center float-left title-wrap">{{ product?.minOrderQuantity }}</div>
  <div class="col-md-1 text-center float-left p-res-0 p-0">
    {{salesOrg.name}}
  </div>
</div>
