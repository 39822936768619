import { Component, ComponentRef, OnInit, ViewContainerRef, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { TranslationService } from '@spartacus/core';
import { LaunchDialogService, LAUNCH_CALLER } from '@spartacus/storefront';
import { Observable, Subscription } from 'rxjs';
import { KnBrInvoiceAccountSummaryObject } from 'src/app/models/kn-br-invoice-account-summary.model';
import { KnBrInvoiceDueAmount } from 'src/app/models/kn-br-invoice.model';
import { KnBrInvoiceService } from 'src/app/store/kn-br-invoice/kn-br-invoice.service';

@Component({
  selector: 'app-kn-br-invoice-due-balance',
  templateUrl: './kn-br-invoice-due-balance.component.html',
  styleUrls: ['./kn-br-invoice-due-balance.component.scss'],
})
export class KnBrInvoiceDueBalanceComponent implements OnInit, OnDestroy {
  dueList$: Observable<KnBrInvoiceDueAmount[]> = this.knBrInvoiceService.duesAmount$;
  duelist = [];
  totalBalance: any;
  subscription = new Subscription();
  constructor(
    public knBrInvoiceService: KnBrInvoiceService,
    protected translation: TranslationService,
    protected launchDialogService: LaunchDialogService,
    protected vcr: ViewContainerRef,
    protected cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.knBrInvoiceService.loadDues();
    this.subscription.add(
      this.dueList$.subscribe((response: any) => {
        if (response) {
          const list = [...response];
          this.duelist = list.slice(0, -1);
          this.totalBalance = response.filter(item => item.name == 'totalBalance');
          this.cd.markForCheck();
        }
      })
    );
  }

  search(data: string) {
    const params = {lowerLimit: '-1' , upperLimit: '0', status: 'open' ,currentPage:0, filterBy:'DOCUMENT_TYPE'}
    if(data === 'CURRENT') {
      params.lowerLimit = 'CURRENT'
    }else if(data.indexOf('+') > -1) {
      params.lowerLimit = data;
      params.upperLimit = '-1';
    } else  if(data.indexOf('-') > -1) {
      const _data = data.split('-');
      params.lowerLimit = _data[1];
      params.upperLimit = _data[0];
    }
    this.knBrInvoiceService.search(params);
  }

  formatValue(val) {
    const toNumber = Number(val);
    if (Number(val)) {
      return toNumber.toLocaleString();
    } else {
      return val;
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
