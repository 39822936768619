import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'knbr-kn-br-common-address',
  templateUrl: './kn-br-common-address.component.html'
})
export class KnBrCommonAddressComponent implements OnInit {
  @Input() address;
  @Input() addressLabel;

  constructor() { }

  ngOnInit(): void {}

  formatZipcode(value) {
    if (value?.length > 5) {
      return value.replace(/(\d{5})/, "$1-");
    } else {
      return value;
    }
  }

}
