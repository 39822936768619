import { RoutingService, GlobalMessageService, GlobalMessageType } from '@spartacus/core';
import { KnBrEditOrderRequest, KnBrEditOrderRequestEntryInput, KnBrAddRequestEntry } from './../../../models/kn-br-order.model';
import { KnBrCommonService } from 'src/app/services/kn-br-common.service';
import { KnBrOrderHistoryService } from './../../../store/kn-br-order-history/kn-br-order-history.service';
import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import * as _ from 'lodash';
import { OrderDetailsService } from '@spartacus/order/components';
import { NgbPanelChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { KnBrQuotesService } from 'src/app/store/kn-br-quotes/kn-br-quotes.service';

@Component({
  selector: 'knbr-kn-br-order-details-items',
  templateUrl: './kn-br-order-details-items.component.html',
  styleUrls: ['./kn-br-order-details-items.component.scss'],
})
export class KnBrOrderDetailsItemsComponent implements OnInit, OnDestroy {
  order$: Observable<any>;
  subscription = new Subscription();
  isOrderEditable$: Observable<boolean> = this.knBrOrderHistoryService.isEditOrderable$();
  editItems: KnBrEditOrderRequestEntryInput[] = [];
  addedProductItems: KnBrAddRequestEntry[] = [];
  editRequest: KnBrEditOrderRequest;
  subscrption = new Subscription();
  detailsForm: UntypedFormGroup;
  lineItemstatuses;
  orderCode: string;
  priceUnit: any;
  show = false;
  @Input()
  index;
  inlineOrders = []

  constructor(
    protected orderDetailsService: OrderDetailsService,
    protected knBrOrderHistoryService: KnBrOrderHistoryService,
    protected knBrCommonService: KnBrCommonService,
    protected routingService: RoutingService,
    protected messageService: GlobalMessageService,
    protected fb: UntypedFormBuilder,
    protected knBrQuoteService: KnBrQuotesService,
  ) {
    this.routingService
      .getRouterState()
      .subscribe((state) => {
        if (state && state.state && state.state.params && state.state.params.orderCode) {
          this.orderCode = state.state.params.orderCode;
          this.editRequest = {
            orderCode: this.orderCode,
            editOrderRequest: {
              editRequestEntryInputs: this.editItems,
              addRequestEntryInputs: this.addedProductItems,
            },
          };
        }
      })
      .unsubscribe();
    this.subscription.add(
      this.knBrOrderHistoryService.loadSaveEditOrderRequest$().subscribe((saveRequest) => {
        if (saveRequest) {
          if (this.editRequest && this.editRequest.orderCode) {
            const canEditOrder = this.editRequest.editOrderRequest.editRequestEntryInputs
              .filter((editItem) => editItem.quantity === 0 && !editItem.cancelReason)
              .map((item) => item.orderEntryNumber)
              .join(',');
            if (canEditOrder) {
              this.messageService.add(
                { key: 'knbrOrderDetails.editOrder.cancelSelectWarning' },
                GlobalMessageType.MSG_TYPE_WARNING
              );
            } else {
              this.knBrOrderHistoryService.editOrder(this.editRequest);
            }
          }
        }
      })
    );
  }

  ngOnInit(): void {
    this.order$ = this.orderDetailsService.getOrderDetails();
    this.knBrCommonService.getCancelOrderStatusList();
    this.detailsForm = this.fb.group({ statusFilter: null });

    this.subscription.add(
      this.order$.subscribe((res: any) => {
        if (res.orderEntries && res.orderEntries.length > 0) {
          this.inlineOrders = res.orderEntries;
          const options = [];
          options.push({"Key": 20, "Text": 'All'});
          Object.keys(res.orderEntries).forEach((key) => {
            if (res.orderEntries[key].lineItemStatus !== undefined && res.orderEntries[key].lineItemStatus !== 'Rejected') {
              options.push({"Key": key, "Text": res.orderEntries[key].lineItemStatus});
            }
          });
          res.orderEntries.find(item => {
            if (item.lineItemStatus === undefined) {
              options.push({"Key": 9, "Text": 'None'});
            }
          });
          this.lineItemstatuses = _.uniqBy(options, 'Text');
        }
      })
    );
  }

  statusChange(event) {
    this.order$.subscribe(res => {
      if (event.Text === 'All') {
        this.inlineOrders = res.orderEntries;
      } else if (event.Text === 'None') {
        this.inlineOrders = res.orderEntries.filter(item => item.lineItemStatus === undefined);
      } else {
        this.inlineOrders = res.orderEntries.filter(item => item.lineItemStatus === event.Text);
      }
    })
  }

  getPriceUnit(type: string, item:any) {
    return (type === 'ZTD' || type === 'ZQTD') ? '-' : `${item?.knbrTotalPrice?.formatedNetPrice} ${item?.knbrTotalPrice?.conditionUnit}`;
  }

  onQuantityChange(eventData) {
    const index = _.findIndex(this.editItems, { orderEntryNumber: eventData.orderEntryNumber });

    // Replace item at index using native splice
    if (index >= 0) {
      this.editItems[index] = eventData;
    } else {
      this.editItems.push(eventData);
    }
    this.editRequest = {
      orderCode: this.orderCode,
      editOrderRequest: {
        editRequestEntryInputs: this.editItems,
        addRequestEntryInputs: this.addedProductItems,
      },
    };
  }

  onInputChange(eventData) {
    this.addedProductItems = eventData;
    this.editRequest = {
      orderCode: this.orderCode,
      editOrderRequest: {
        editRequestEntryInputs: this.editItems,
        addRequestEntryInputs: this.addedProductItems,
      },
    };
  }

  goBack() {
    this.routingService.go({ cxRoute: 'orders' }, { state: { displayPersistedValues: true }});
  }

  showHide($event: NgbPanelChangeEvent, acc) {
    if (!acc.isExpanded($event.panelId)) {
      const idx = $event.panelId.slice(-1)
      this.show = true;
      this.index = idx;
    } else {
      this.show = false;
    }
  }

  navigateToQuote(quoteNum) {
    this.knBrQuoteService.get(quoteNum);
    this.routingService.go({ cxRoute: 'quoteDetails', params: { code: quoteNum } });
  }

  ngOnDestroy() {
    this.knBrOrderHistoryService.setOrderEditable(false);
    this.knBrOrderHistoryService.setSaveEditOrderRequest(false);
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
