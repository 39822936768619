import { Component, ComponentRef, OnInit, ViewContainerRef, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { TranslationService } from '@spartacus/core';
import { LaunchDialogService, LAUNCH_CALLER } from '@spartacus/storefront';
import { Observable, Subscription } from 'rxjs';
import { KnBrInvoiceAccountSummaryObject } from 'src/app/models/kn-br-invoice-account-summary.model';
import { KnBrInvoiceDueAmount } from 'src/app/models/kn-br-invoice.model';
import { KnBrInvoiceService } from 'src/app/store/kn-br-invoice/kn-br-invoice.service';

@Component({
  selector: 'app-kn-br-invoice-account-summary',
  templateUrl: './kn-br-invoice-account-summary.component.html',
  styleUrls: ['./kn-br-invoice-account-summary.component.scss'],
})
export class KnBrInvoiceAccountSummaryComponent implements OnInit, OnDestroy {
  accountSummaryResult$: Observable<KnBrInvoiceAccountSummaryObject> = this.knBrInvoiceService.accountSummary$;

  loader$: Observable<boolean> = this.knBrInvoiceService.loader$;
  showSpinner: void | Observable<ComponentRef<any>>;
  subscription = new Subscription();

  constructor(
    public knBrInvoiceService: KnBrInvoiceService,
    protected translation: TranslationService,
    protected launchDialogService: LaunchDialogService,
    protected vcr: ViewContainerRef,
    protected cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.getAccountSummary();
    this.subscription.add(
      this.loader$.subscribe((isLoading) => {
        this.onSpinnerLoading(isLoading);
      })
    );
  }

  onSpinnerLoading(isLoading) {
    if (isLoading) {
      this.showSpinner = this.launchDialogService.launch(LAUNCH_CALLER.PLACE_ORDER_SPINNER, this.vcr);
    } else {
      if (this.showSpinner) {
        this.showSpinner
          .subscribe((component) => {
            this.launchDialogService.clear(LAUNCH_CALLER.PLACE_ORDER_SPINNER);
            component.destroy();
          })
          .unsubscribe();
      }
    }
  }

  formatPhone(value) {
    const trim = value?.substring(2);
    const cleaned = ('' + trim).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    } else {
      const removeSpaces = value?.replace(/\s/g, '');
      const trim = removeSpaces?.substring(1);
      const match = trim?.match(/^(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        return '(' + match[1] + ') ' + match[2] + '-' + match[3];
      }
    }
  }

  getAccountSummary() {
    this.knBrInvoiceService.accountSummary();
  }


  ngOnDestroy() {
    this.launchDialogService.clear(LAUNCH_CALLER.PLACE_ORDER_SPINNER);
    this.subscription.unsubscribe();
  }
}
