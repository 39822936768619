import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthGuard, ConfigModule, RoutingConfig } from '@spartacus/core';
import { KnBrInvoiceListSearchModule } from './kn-br-invoice-list-search/kn-br-invoice-list-search.module';
import { KnBrInvoiceListSearchResultModule } from './kn-br-invoice-list-seach-result/kn-br-invoice-list-search-result.module';
import { RouterModule } from '@angular/router';
import { CmsPageGuard, PageLayoutComponent } from '@spartacus/storefront';
import { KnBrCustomerContextGuard } from 'src/app/shared/guards/kn-br-customer-context.guard';
import { KnBrInvoiceAccountSummaryModule } from './kn-br-invoice-account-summary/kn-br-invoice-account-summary.module';
import { KnBrInvoiceDueBalanceModule } from './kn-br-invoice-due-balance/kn-br-invoice-due-balance.module';

@NgModule({
  imports: [
    CommonModule,
    KnBrInvoiceAccountSummaryModule,
    KnBrInvoiceListSearchModule,
    KnBrInvoiceListSearchResultModule,
    KnBrInvoiceDueBalanceModule,
    RouterModule.forChild([
      {
        data: { pageLabel: '/customer-360/invoice-list', cxRoute: 'invoiceList' },
        path: null,
        canActivate: [AuthGuard, CmsPageGuard, KnBrCustomerContextGuard],
        component: PageLayoutComponent,
      },
    ]),
    ConfigModule.withConfig({
      routing: {
        routes: {
          invoiceList: {
            paths: ['customer-360/invoice-list'],
          },
        },
      },
    } as RoutingConfig),
  ],
})
export class KnBrInvoiceListModule {}
