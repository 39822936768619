<ng-container>
  <div class="col-md-12 p-0 pb-4 float-right d-flex justify-content-between">
    <h2 class="mt-2 font-text-600">{{ this.title(this.invoiceName) }}</h2>
    <button class="btn back-btn p-1 font-lg-size" (click)="goBack()">
      <i class="fas fa-level-down-alt fa-rotate-90" aria-hidden="true"></i>
    </button>
  </div><br><br>
  <hr>
  <div class="col-md-12 float-left p-0 pb-2 mt-2 mb-4 ">
    <div class="col-md-4 float-left mt-1 p-0">
      <div class="col-12 float-left mt-1 p-0">
        <label class="color-font font-text-600 mb-1">
          {{ 'knbrInvoiceDetails.label.documentNumberDate' | cxTranslate }}
        </label>
        <p class="text-muted mb-2">{{this.invoiceData?.documentNumber}} / {{this.invoiceData?.invoiceDate}}</p>
      </div>
      <div class="col-12 float-left mt-1 p-0">
        <label class="color-font font-text-600 mb-1">
          {{ 'knbrInvoiceDetails.label.purchaseOrderNum' | cxTranslate }}
        </label>
        <p class="text-muted mb-2">{{this.invoiceData?.purchaseOrder}}</p>
      </div>
      <div *ngIf="this.invoiceData?.poDate" class="col-12 float-left mt-1 p-0">
        <label class="color-font font-text-600 mb-1">
          {{ 'knbrInvoiceDetails.label.purchaseOrderDate' | cxTranslate }}
        </label>
        <p class="text-muted mb-2">{{this.invoiceData?.poDate}}</p>
      </div>
      <div *ngIf="this.invoiceData?.packingListNo" class="col-12 float-left mt-1 p-0">
        <label class="color-font font-text-600 mb-1">
          {{ 'knbrInvoiceDetails.label.packingList' | cxTranslate }}
        </label>
        <p class="text-muted mb-2">{{this.invoiceData?.packingListNo}} / {{this.invoiceData?.packingListDate}}</p>
      </div>
      <div class="col-12 float-left mt-1 p-0">
        <label class="color-font font-text-600 mb-1">
          {{ 'knbrInvoiceDetails.label.salesOrderNum' | cxTranslate }}
        </label>
        <p class="text-muted mb-2">{{this.invoiceData?.salesOrderNumber}} / {{this.invoiceData?.salesOrderDate}}</p>
      </div>
      <div class="col-12 float-left mt-1 p-0">
        <label class="color-font font-text-600 mb-1">
          {{ 'knbrInvoiceDetails.label.customerNum' | cxTranslate }}
        </label>
        <p class="text-muted mb-2">{{this.invoiceData?.customerNumber}}</p>
      </div>
      <div class="col-12 float-left mt-1 p-0">
        <label class="color-font font-text-600 mb-1">
          {{ 'knbrInvoiceDetails.label.saleGroup' | cxTranslate }}
        </label>
        <p class="text-muted mb-2">{{this.invoiceData?.salesGroup}}</p>
      </div>
      <div class="col-12 float-left mt-1 p-0">
        <label class="color-font font-text-600 mb-1">
          {{ 'knbrInvoiceDetails.label.brand' | cxTranslate }}
        </label>
        <p class="text-muted mb-2">{{this.invoiceData?.brandName}}</p>
      </div>
    </div>

    <div class="col-md-5 float-left mt-1 p-0">
      <knbr-kn-br-common-address [address]="this.invoiceData?.billToAddress" [addressLabel]="'Bill-To-Address'"></knbr-kn-br-common-address>
      <knbr-kn-br-common-address [address]="this.invoiceData?.soldToAddress" [addressLabel]="'Sold To Account'"></knbr-kn-br-common-address>
      <knbr-kn-br-common-address [address]="this.invoiceData?.shipToAddress" [addressLabel]="'Ship To Account'"></knbr-kn-br-common-address>

      <div class="col-12 float-left mt-1 p-0">
        <label class="color-font font-text-600 mb-1">
          {{ 'knbrInvoiceDetails.label.termsOfPayment' | cxTranslate }}
        </label>
        <span class="text-muted mb-2">{{this.invoiceData?.termsOfPayment}}</span>
        <p class="text-muted mb-2" hidden>{{this.formatDescription(this.invoiceData?.termsOfPaymentDesc)}}</p>
        <div *ngFor="let note of termsOfPayment">
          <p class="mb-0 text-muted">{{ note }}</p>
        </div>
      </div>

      <div *ngIf="showRemittanceAddress">
        <knbr-kn-br-common-address [address]="this.invoiceData?.remittanceAddress" [addressLabel]="'Remittances Address'"></knbr-kn-br-common-address>
      </div>
    </div>
    <div class="col-md-3 float-left mt-1 p-0">
      <div *ngIf="this.invoiceData?.discounts && this.invoiceData?.discounts?.length > 0" class="col-md-12 float-left mt-1 p-0">
        <div *ngFor="let item of this.invoiceData?.discounts; let i = index">
          <label class="color-font font-text-600 mb-1">
            {{ 'knbrInvoiceDetails.label.discountAmount' | cxTranslate }}{{i + 1}}
          </label>
          <p class="text-muted mb-2">{{this.separator(item.value)}}</p>
        </div>
      </div>
      <div class="col-md-12 float-left mt-1 p-0">
        <label class="color-font font-text-600 mb-1">
          {{ 'knbrInvoiceDetails.label.termsOfDelivery' | cxTranslate }}
        </label>
        <p class="text-muted mb-2">{{this.invoiceData?.termsOfDelivery}} - {{this.invoiceData?.termsOfDeliveryDesc}}</p>
      </div>
      <div *ngIf="this.invoiceData?.netWeight" class="col-md-12 float-left mt-1 p-0">
        <label class="color-font font-text-600 mb-1">
          {{ 'knbrInvoiceDetails.label.netWeight' | cxTranslate }}
        </label>
        <p class="text-muted mb-2">{{this.toFixed(this.invoiceData?.netWeight)}} {{this.invoiceData?.netWeightUOM}}</p>
      </div>
      <div *ngIf="this.invoiceData?.volume" class="col-md-12 float-left mt-1 p-0">
        <label class="color-font font-text-600 mb-1">
          {{ 'knbrInvoiceDetails.label.volume' | cxTranslate }}
        </label>
        <p class="text-muted mb-2">{{this.toFixed(this.invoiceData?.volume)}} {{this.invoiceData?.volumeUOM}}</p>
      </div>
      <div *ngIf="this.invoiceData?.shipment" class="col-md-12 float-left mt-1 p-0">
        <label class="color-font font-text-600 mb-1">
          {{ 'knbrInvoiceDetails.label.shipmentBlNum' | cxTranslate }}
        </label>
        <p class="text-muted mb-2">{{this.invoiceData?.shipment}}</p>
      </div>
      <div *ngIf="this.invoiceData?.carrier" class="col-md-12 float-left mt-1 p-0">
        <label class="color-font font-text-600 mb-1">
          {{ 'knbrInvoiceDetails.label.carrier' | cxTranslate }}
        </label>
        <p class="text-muted mb-2">{{this.invoiceData?.carrier}}</p>
      </div>
      <div *ngIf="this.invoiceData?.truck" class="col-md-12 float-left mt-1 p-0">
        <label class="color-font font-text-600 mb-1">
          {{ 'knbrInvoiceDetails.label.truckProNum' | cxTranslate }}
        </label>
        <p class="text-muted mb-2">{{this.invoiceData?.truck}}</p>
      </div>
      <div class="col-md-12 float-left mt-1 p-0">
        <label class="color-font font-text-600 mb-1">
          {{ 'knbrInvoiceDetails.label.currency' | cxTranslate }}
        </label>
        <p class="text-muted mb-2">{{this.invoiceData?.currencyISO}}</p>
      </div>
      <div *ngIf="this.invoiceData?.shippingPoint" class="col-md-12 float-left mt-1 p-0">
        <label class="color-font font-text-600 mb-1">
          {{ 'knbrInvoiceDetails.label.shippingPoint' | cxTranslate }}
        </label>
        <p class="text-muted mb-2">{{this.invoiceData?.shippingPoint}}</p>
      </div>
    </div>
  </div>
</ng-container>
