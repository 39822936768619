<ng-container *ngIf="orderReturnData$ | async as order">
  <div class="col-md-12 p-0 float-right d-flex justify-content-end">
    <button class="btn back-btn p-1 font-lg-size" (click)="goBack()">
      <i class="fas fa-level-down-alt fa-rotate-90" aria-hidden="true"></i>
    </button>
  </div>
  <div class="col-md-12 float-left p-0 pb-2 mt-2 mb-4 ">
    <div class="col-md-8 float-left mt-3 p-0">
      <div class="col-md-6 float-left mt-3 p-0">
        <label class="color-font font-text-600 mb-1">
          {{ 'knbrReturnOrderDetails.label.returnCreateDate' | cxTranslate }}
        </label>
        <p class="mb-2 text-muted">{{ order.returnRequestDate }}</p>
      </div>
      <!-- <div class="col-md-6 float-left mt-3 p-0">
        <label class="color-font font-text-600 mb-1">
          {{ 'knbrReturnOrderDetails.label.totalReturnValue' | cxTranslate }}
        </label>
        <p class="mb-2">{{ order.totalPrice.formattedValue }}</p>
      </div> -->
      <div class="col-md-6 float-left mt-3 p-0">
        <label class="color-font font-text-600 mb-1">
          {{ 'knbrReturnOrderDetails.label.returnNumber' | cxTranslate }}
        </label>
        <p class="mb-2 text-muted">{{ order.rma | removeLeadingZeros }}</p>
      </div>
      <div class="col-md-6 float-left mt-3 p-0">
        <label class="color-font font-text-600 mb-1">
          {{ 'knbrReturnOrderDetails.label.shipTo' | cxTranslate }}
        </label>
        <p class="float-left mb-2" *ngIf="order?.deliveryAddress">
          <span class="w-100 text-muted float-left">{{ order.deliveryAddress?.firstName }} {{ order.deliveryAddress?.lastName
            }}</span>
          <span class="w-100 text-muted float-left">{{ order.deliveryAddress?.line1 }}</span>
          <span class="w-100 text-muted float-left">{{ order.deliveryAddress?.town }}</span>
          <span class="w-100 text-muted float-left">{{ order.deliveryAddress?.region?.name }}, {{ order.deliveryAddress?.postalCode }}</span>
          <span class="w-100 text-muted float-left">{{ order.deliveryAddress?.country?.isocode }}</span>
          <span class="w-100 text-muted float-left">{{ this.formatPhone(order.deliveryAddress?.phone) }}</span>
        </p>
      </div>

      <div class="col-md-6 float-left mt-3 p-0">
        <label class="color-font font-text-600 mb-1">{{ 'knbrReturnOrderDetails.label.soldTo' | cxTranslate }}</label>
        <p class="float-left mb-2" *ngIf="order?.soldTo">
          <span class="w-100 text-muted float-left">{{ order.soldTo?.customerName }} {{ order.soldTo?.name2}}</span>
          <span class="w-100 text-muted float-left">{{ order.soldTo?.street }}</span>
          <span class="w-100 text-muted float-left">{{ order.soldTo?.city }}</span>
          <span class="w-100 text-muted float-left">{{ order.soldTo?.state }}, {{order.soldTo?.postalCode }}</span>
          <span class="w-100 text-muted float-left">{{ order.soldTo?.country }}</span>
          <span class="w-100 text-muted float-left">{{ this.formatPhone(order.soldTo?.telephone) }}</span>
        </p>
      </div>

      <div class="col-md-6 float-left mt-3 p-0">
        <label class="color-font font-text-600 mb-1">
          {{ 'knbrReturnOrderDetails.label.overallStatus' | cxTranslate }}
        </label>
        <p class="mb-2 text-muted">{{ order.statusDisplay }}</p>
      </div>

      <div class="col-md-6 float-left mt-3 p-0">
        <label class="color-font font-text-600 mb-1">
          {{ 'knbrReturnOrderDetails.label.orderNumber' | cxTranslate }}
        </label>
        <p class="mb-2 text-muted">{{ order.order.code | removeLeadingZeros }}</p>
      </div>
      <div class="col-md-6 float-left mt-3 p-0">
        <label class="color-font font-text-600 mb-1">
          {{ 'knbrReturnOrderDetails.label.PO' | cxTranslate }}
        </label>
        <p class="mb-2 text-muted">{{ order.purchaseOrderNumber }}</p>
      </div>
      <div *ngIf="order.creditMemoNumber" class="col-md-6 float-left mt-3 p-0">
        <label class="color-font font-text-600 mb-1">
          {{ 'knbrReturnOrderDetails.label.creditMemoNumber' | cxTranslate }}
        </label>
        <p class="mb-2 text-muted">{{ order.creditMemoNumber | removeLeadingZeros }}</p>
      </div>
    </div>
    <div class="col-md-4 float-left mt-3 p-0">
      <div class="col-md-12 float-left mt-3 p-0">
        <label class="color-font font-text-600 mb-1">
          {{ 'knbrReturnOrderDetails.heading.attachments' | cxTranslate }}
        </label>
        <p class="float-left mb-2" *ngIf="order.carlisleSaleDocs && order?.carlisleSaleDocs?.length > 0">
          <a (click)="downloadPdf(doc)" class=" cursor-pointer pt-2 w-100 float-left doc-link"
          *ngFor="let doc of order.carlisleSaleDocs">{{ doc?.documentName }}</a>
        </p>
      </div>
      <div class="col-md-12 float-left mt-3 p-0">
        <label class="color-font font-text-600 mb-1">
          {{ 'knbrReturnOrderDetails.label.returnReason' | cxTranslate }}
        </label>
        <p class="mb-2 text-muted">{{ order.refundReason }}</p>
      </div>
      <div class="col-md-12 float-left mt-3 p-0">
        <label class="color-font font-text-600 mb-1">
          {{ 'knbrReturnOrderDetails.label.returnNote' | cxTranslate }}
        </label>
        <p hidden>{{ this.splitReturnNotes(order.notes) }}</p>
        <div *ngFor="let note of returnNotes; let i = index">
          <p class="mb-0 text-muted" *ngIf="i !== this.returnIdx">{{ note }}</p>
          <p class="mb-0 text-muted" *ngIf="i === this.returnIdx"><br>{{ note }}</p>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="!orderReturnLoading && !orderReturnSuccess">
  <div class="col-md-12 float-right d-flex justify-content-center mt-4">
    <button type="button" class="btn btn-action col-md-3" (click)="goBack()">
      {{ 'knbrReturnOrderDetails.actions.backOrderReturns' | cxTranslate }}
    </button>
  </div>
</ng-container>
