<div class="col-md-12 float-left border border-left-0 p-5 mb-4">
  <div class="col-lg-4 p-0 float-left">
    <label class="color-font font-text-400 text-muted mb-1">
      {{ 'knbrAccountSummary.label.accountTodaysBalance' | cxTranslate }}
    </label>
    <p class="mb-2">
      {{ this.totalBalance[0]?.value }}
    </p>
  </div>
  <div class="offset-lg- col-lg-6 p-0 float-left">
    <table class="table dueList">
      <thead>
        <tr>
          <th></th>
          <th class="text-left pt-0">
            <label class="color-font font-text-600 mb-1">
              {{ 'knbrAccountSummary.label.accountDueItems' | cxTranslate }}
            </label>
          </th>
        </tr>
      </thead>
      <tbody *ngIf="this.duelist">
        <tr *ngFor="let due of this.duelist; let i = index">
          <td><a (click)="search(due.name)">{{ due.name }}</a></td>
          <td class="dueValues">{{ formatValue(due?.value) }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
