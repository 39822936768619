<ng-container *ngIf="order$ | async as order">
  <div class="header row ml-0 d-flex justify-content-between">
    <div class="alert alert-secondary justify-content-start" role="alert">
      <h3><b><i>Check Scheduled Delivery Dates at Line item</i></b></h3>
    </div>
    <div class="col-lg-2 mt-3" [formGroup]="detailsForm">
      <ng-select class="mb-1" formControlName="statusFilter" bindLabel="Text" bindValue="Key" placeholder="All"
        [items]="lineItemstatuses" [searchable]="false" [clearable]="false" (change)="statusChange($event)" bindLabel="Text" bindValue="Key">
      </ng-select>
      <label class="text-muted">{{ 'knbrOrderDetails.label.filterByStatus' | cxTranslate }}</label>
    </div>
  </div>
  <ng-container *ngIf="this.inlineOrders">
    <div class="col-12 col-sm float-left p-0 mb-3">
      <div class="col-12 col-sm float-left p-0 align-items-center" *ngFor="let item of this.inlineOrders; let i = index" [ngClass]="{ 'border-2': item.showLineItem }">
        <div *ngIf="item.showLineItem === true" >
          <div class="col-md-2 col-sm float-left" [ngClass]="{ 'unconsigned-item': !item.consignmentEntry }">
            <a [routerLink]="{ cxRoute: 'product', params: item.product } | cxUrl" cxModal="dismiss" tabindex="-1"
              *ngIf="item.product.showInCatalog === 'Yes' && item.showLineItem === true">
              <cx-media [container]="item.product?.images" format="product"></cx-media>
            </a>
            <a tabindex="-1" *ngIf="item.product.showInCatalog !== 'Yes'">
              <cx-media [container]="item.product?.images" format="product"></cx-media>
            </a>
          </div>
          <div class="col-md-10 col-sm float-left">
            <div class="col-md-12 col-sm float-left p-0 pt-2 pb-2 border-bottom">
              <label class="font-text-400 color-font font-size-18">{{ item.product?.code }}</label>
              <div *ngIf="item.product?.name" >
                <a class="text-muted font-text-400" [routerLink]="{ cxRoute: 'product', params: item.product } | cxUrl"
                  cxModal="dismiss" *ngIf="item.product.showInCatalog === 'Yes'"><b>{{ item.product?.name }}</b></a>
                  <a class="text-muted font-text-400"  *ngIf="item.product.showInCatalog !== 'Yes'"><b>{{ item.product?.name }}</b></a>
              </div>
            </div>
            <ng-container *ngIf="!(isOrderEditable$ | async)">
              <div class="col-md-12 col-sm float-left p-0 pt-2 pb-2 border-1" [ngClass]="{ 'border-bottom': item.consignmentEntry }">
                <div class="col-md-1 float-left p-0 col-md-1">
                  <label class="font-text-400 order-details-table-row-height font-weight-bold">{{ 'knbrOrderDetails.label.poNumber2' | cxTranslate}}</label>
                  <p class="text-muted mb-1">{{item.purchaseOrderNumber }}</p>
                </div>
                <div class="col-md-1 col-sm float-left p-0">
                  <label class="font-text-400 order-details-table-row-height font-weight-bold">{{ 'knbrOrderDetails.label.qty' | cxTranslate }}</label>
                  <p class="text-muted mb-1">{{ item?.quantity?.toLocaleString() }} {{ item.uom }}</p>
                </div>

                <div class="col-md-2 col-sm float-left p-0">
                  <label class="font-text-400 order-details-table-row-height font-weight-bold">{{ 'knbrOrderDetails.label.plant' | cxTranslate }}</label>
                  <p class="text-muted mb-1">{{ item.plant }}</p>
                </div>
                <div class="col-md-1 col-sm float-left p-0">
                  <label class="font-text-400 order-details-table-row-height font-weight-bold">{{ 'knbrOrderDetails.label.lineItemStatus' | cxTranslate }}</label>
                  <p class="text-muted mb-1">{{ item.lineItemStatus }}</p>
                </div>
                <!-- <div class="col-md-1 col-sm float-left p-0"
                  *ngIf="item.knbrQuoteReference && item.knbrQuoteReference?.quoteRefNumber">
                  <label class="font-text-400 order-details-table-row-height font-weight-bold">{{ 'knbrOrderDetails.label.quoteId' | cxTranslate }}</label>
                  <p class="text-muted mb-1">{{ item.knbrQuoteReference?.quoteRefNumber }}</p>
                </div> -->
                <div class="col-md-1 col-sm float-left p-0">
                  <label class="font-text-400 order-details-table-row-height font-weight-bold">{{ 'knbrOrderDetails.label.openQuantity' | cxTranslate }}</label>
                  <p class="text-muted mb-2">{{ item.openQuantity?.toLocaleString() }} {{ item.uom }}</p>
                </div>
                <div class="col-md-2 col-sm float-left p-0">
                  <label class="font-text-400 pl-2 order-details-table-row-height font-weight-bold">{{ 'knbrOrderDetails.label.termsOfDelivery' | cxTranslate }}</label>
                  <p class="text-muted mb-2">{{ item.incoTerm }}</p>
                </div>
                <div class="col-md-1 col-sm float-left p-0">
                  <label class="font-text-400 order-details-table-row-height font-weight-bold">{{ 'knbrOrderDetails.label.unitNetPrice' | cxTranslate }}</label>
                  <p class="text-muted mb-2">{{ getPriceUnit(order.knbrOrderType, item) }}</p>
                </div>
                <div class="col-md-2 float-left p-0">
                  <label class="font-text-400 order-details-table-row-height font-weight-bold">{{ 'knbrOrderDetails.label.shipTo' | cxTranslate }}</label>
                    <p class="text-muted mb-1 word-wrap">{{ item?.shipTo }}</p>
                </div>
                <div class="col-md-1 col-sm float-left p-0">
                  <label class="font-text-400 order-details-table-row-height font-weight-bold">{{ 'knbrOrderDetails.label.quoteReference' | cxTranslate }}</label>
                    <a class="mb-1 cursor-pointer" (click)="navigateToQuote(item?.quoteRefNumber)">{{ item?.quoteRefNumber ? item?.quoteRefNumber : '-' }}</a>
                </div>
              </div>
            </ng-container>
            <knbr-kn-br-edit-order-item *ngIf="isOrderEditable$ | async" [item]="item"
              (inputChange)="onQuantityChange($event)">
            </knbr-kn-br-edit-order-item>
            <ng-container>
              <ngb-accordion #acc="ngbAccordion" class="scheduled" activeIds="ngb-panel-0" [closeOthers]="true" (panelChange)="showHide($event, acc)">
                <ngb-panel class="w-100 float-left mb-2 text-muted font-size-18">
                  <ng-template ngbPanelTitle="">
                    <div class="d-flex justify-content-between">
                      <div class="d-flex">Scheduled Delivery Dates</div>
                      <div class="d-flex"><i class="fas ml-3 mt-1" [ngClass]="this.show && this.index == i ? 'fa-chevron-up' : 'fa-chevron-down'"></i></div>
                    </div>
                  </ng-template>
                  <ng-template ngbPanelContent>
                    <div class="row counter-wrapper" *ngIf="item && item?.scheduleLines?.length">
                      <div class="customersearch-list-table float-left col-lg-12 mt-3">
                        <div class="float-left w-100">
                          <div class="col-md-4 float-left pl-0 py-2 font-weight-bold color-font">Scheduled Qty</div>
                          <div class="col-md-4 float-left pl-0 py-2 font-weight-bold color-font">Scheduled Date</div>
                        </div>
                        <div class="bg-white table-content float-left w-100 p-2 font-weight-normal d-flex align-items-center" *ngFor="let item of item?.scheduleLines">
                          <div class="col-md-4 float-left title-wrap p-2 font-md-size text-muted mb-1">
                            <span class="res-titles"> {{ 'invoiceResult.header.document' | cxTranslate }}:</span>
                            {{ item.scheduledQuantity?.toLocaleString() }} {{ item.uom }}
                          </div>
                          <div class="col-md-4 float-left title-wrap pl-2 font-md-size text-muted mb-1">
                            <span class="res-titles"> {{ 'invoiceResult.header.documentDate' | cxTranslate }}:</span>
                            {{ item.scheduledDate }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </ngb-panel>
              </ngb-accordion>
            </ng-container>
            <ng-container>
              <div class="col-2 float-left p-0 pt-2 pb-2">
                <label class="w-100 float-left mb-2 text-muted font-size-18"><b>{{'knbrOrderDetails.label.shipments' | cxTranslate }}</b>
                </label>
              </div>
              <div *ngIf="item?.dropShipment" class="col-10 float-left p-0 pt-2 pb-2 border-1">
                <span><i>No information available, please contact customer service</i></span>
              </div>
              <ng-container *ngFor="let consignment of item.shipments">
                <div class="col-12 float-left p-0 pt-2 pb-2">
                  <div class="col-12 float-left p-0">
                    <div class="col-1 float-left p-0">
                      <label class="color-font font-text-400 mb-1">{{'knbrOrderDetails.label.qtyShipped' | cxTranslate}}</label>
                      <p class="text-muted mb-1">{{ consignment.qtyShipped }} {{ item.uom }}</p>
                    </div>
                    <div class="col-1 float-left p-0">
                      <label class="color-font font-text-400 mb-1">{{'knbrOrderDetails.label.shipDate' | cxTranslate}}</label>
                      <p class="text-muted mb-1">{{ consignment.shippedDate }}</p>
                    </div>
                    <div class="col-2 float-left p-0">
                      <label class="color-font font-text-400 mb-1">{{'knbrOrderDetails.label.status' | cxTranslate}}</label>
                      <p class="text-muted mb-1 ml-2">{{ consignment.shippedStatus }}</p>
                    </div>
                    <div class="col-1 float-left p-0">
                      <label class="color-font font-text-400 mb-1">{{'knbrOrderDetails.label.deliveryNumber' | cxTranslate}}</label>
                      <p class="text-muted mb-1">{{ consignment.deliveryNumber }}</p>
                    </div>
                    <div class="col-2 float-left p-0">
                      <label class="color-font font-text-400 mb-1">{{'knbrOrderDetails.label.shippingType' | cxTranslate}}</label>
                      <p class="text-muted mb-1">{{ consignment.shippingType }}</p>
                    </div>
                    <div class="col-2 float-left p-0">
                      <label class="color-font font-text-400 mb-1">{{'knbrOrderDetails.label.carrier' | cxTranslate}}</label>
                      <p class="text-muted mb-1">{{ consignment.shipmentCarrier }}</p>
                    </div>

                    <div class="col-3 float-left p-0">
                      <label class="color-font font-text-400 mb-1">{{'knbrOrderDetails.label.trackingId' | cxTranslate}}</label>
                        <span *ngIf="consignment.trackingId && consignment.trackingUrl; else callNumber">
                          <a class="mb-1 cursor-pointer cx-link" href="{{consignment.trackingUrl}}" target="_blank">{{ consignment.trackingId }}</a>
                        </span>
                        <ng-template #callNumber>
                          <span class="text-muted mb-1">{{ 'knbrOrderDetails.label.call' | cxTranslate}}</span>
                        </ng-template>
                    </div>
                  </div>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <div class="col-md-12 float-right d-flex justify-content-center mt-2">
    <button type="button" class="btn btn-action" (click)="goBack()">
      {{ 'knbrOrderDetails.actions.backOrderHistory' | cxTranslate }}
    </button>
  </div>
</ng-container>
<ng-container>
  <kn-br-add-products *ngIf="isOrderEditable$ | async" (inputChange)="onInputChange($event)">
  </kn-br-add-products></ng-container>
