<ng-container *ngIf="accountSummaryResult$ | async as accSummaryResult">
  <div class="col-md-12 float-left border border-right-0 p-5 mb-4">
    <div class="col-lg-6 p-0 float-left">
      <label class="color-font font-text-600 mb-1">
        {{ 'knbrAccountSummary.label.accountNumber' | cxTranslate }}
      </label>
      <p class="text-muted mb-2">
        {{ accSummaryResult?.b2bUnitData?.uid | removeLeadingZeros }}
      </p>

      <label class="color-font font-text-600 mb-1">
        {{ 'knbrAccountSummary.label.accountName' | cxTranslate }}
      </label>
      <p class="text-muted mb-2">
        {{ accSummaryResult.b2bUnitData.name }}
      </p>

      <label class="color-font font-text-600 mb-1">
        {{ 'knbrAccountSummary.label.address' | cxTranslate }}
      </label>
      <p class="text-muted mb-0">
        {{ accSummaryResult.billingAddress.line1 }}
      </p>
      <p class="text-muted mb-0">
        {{ accSummaryResult.billingAddress.line2 }}
      </p>
      <p class="text-muted mb-0">
        {{ accSummaryResult.billingAddress.town? accSummaryResult.billingAddress.town : ''}}
      </p>
      <p class="text-muted mb-0">
        {{ accSummaryResult.billingAddress?.region?.name}}, {{ accSummaryResult.billingAddress.postalCode }}
      </p>
      <p class="text-muted mb-0">
        {{ accSummaryResult.billingAddress?.country?.isocode}}
      </p>
      <p class="text-muted mb-0">
        {{ this.formatPhone(accSummaryResult.billingAddress?.phone) }}
      </p>
    </div>

  </div>
</ng-container>
