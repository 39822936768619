import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CmsConfig, ConfigModule, I18nModule } from '@spartacus/core';
import { KnBrInvoiceDueBalanceComponent } from './kn-br-invoice-due-balance.component';

@NgModule({
  declarations: [KnBrInvoiceDueBalanceComponent],
  imports: [
    CommonModule,
    I18nModule,
    ConfigModule.withConfig({
      cmsComponents: {
        "KnBrInvoiceHeaderComponent-due-balance": {
          component: KnBrInvoiceDueBalanceComponent,
        },
      },
    } as CmsConfig),
  ],
})
export class KnBrInvoiceDueBalanceModule {}
