export const KN_BR_CUSTOMERS = 'knbr/purchasingagent/knbrcustomers';
export const KN_BR_CARTS_COUNTER = (customerId: string) => `knbrcounter/customer/${customerId}/carts?fields=DEFAULT`;
export const KN_BR_CARTS_VALUE = (customerId: string) => `knbrcounter/customer/${customerId}/cartsValue?fields=DEFAULT`;
export const KN_BR_QUOTES_COUNTER = (customerId: string) => `knbrcounter/customer/${customerId}/quotes?fields=DEFAULT`;
export const KN_BR_QUOTES_VALUE = (customerId: string) =>
  `knbrcounter/customer/${customerId}/quotesValue?fields=DEFAULT`;
export const KN_BR_DRAFT_ORDER = 'knbr/customer/${customerId}/carts';
export const KN_BR_DRAFT_SEARCH = (customerId: string) =>
  `knbr/customer/${customerId}/carts?fields=BASIC,carts(totalPrice(formattedValue),totalItems)`;
export const KN_BR_DRAFT_ORDER_COPY = 'knbr/customer/${customerId}/carts/${cartId}/clonecart';
export const KN_BR_DRAFT_ORDER_UPDATE = 'knbr/customer/${customerId}/carts/${cartId}';
export const KN_BR_DRAFT_ORDER_DELETE = 'knbr/customer/${customerId}/carts/${cartId}';
export const KNBR_ORDER_TYPE_LIST = (customerId: string) => `knbr/customers/${customerId}/orders/orderType`;
export const KNBR_ORDER_CREDIT_STATUS_LIST = (customerId: string) => `knbr/customers/${customerId}/orders/creditStatus`;
export const KNBR_ORDER_DELIVERY_BLOCK_LIST = (customerId: string) =>
  `knbr/customers/${customerId}/orders/deliveryBlock`;
export const KN_BR_DRAFT_ORDER_CHECKOUT_VALIDATION = 'knbr/customer/${customerId}/carts/${cartId}/validation';
export const KN_BR_ORDER_STATUSES = (customerId: string) => `knbr/customer/${customerId}/carts/current/cartStatus`;
export const KN_BR_QUOTE_STATUSES = (customerId: string) => `knbr/customer/${customerId}/quotes/quoteStatus`;
export const KN_BR_VALIDITY_STATUSES = (customerId: string) => `knbr/customers/${customerId}/quotes/quoteValidity`;
export const KN_BR_QUOTE_SOLD_TO_CITY = (customerId: string) => `knbr/customer/${customerId}/quotes/customerSoldToCities`;

export const KN_BR_DRAFT_ORDER_STATUSES = 'knbr/customer/${customerId}/carts/${cartId}/statuses';

export const KN_BR_QUOTE_ENTRIES = 'knbr/customer/${customerId}/quotes/${quoteId}/entries';
export const KN_BR_QUOTE_UPDATE_ENTRY =
  'knbr/customer/${customerId}/quotes/${quoteId}/entries/${entryNumber}?fields=DEFAULT,entry(totalPrice(formattedValue),product(images(FULL),stock(FULL)),basePrice(formattedValue),updateable)';
export const KN_BR_CUSTOMER_CURRENT = 'current';
export const KN_BR_QUOTE_GET_ADD_COMMENTS =
  'knbr/customer/${customerId}/quotes/${quoteId}/entries/${entryNumber}/comments';
export const KN_BR_QUOTE_UPDATE_DELETE_COMMENTS =
  'knbr/customer/${customerId}/quotes/${quoteId}/entries/${entryNumber}/comments/${commentId}';
export const KN_BR_DRAFT_ORDER_GET_ADD_COMMENTS =
  'knbr/customer/${customerId}/carts/${cartId}/entries/${entryNumber}/comments';
export const KN_BR_DRAFT_ORDER_UPDATE_DELETE_COMMENTS =
  'knbr/customer/${customerId}/carts/${cartId}/entries/${entryNumber}/comments/${commentId}';
export const KN_BR_GET_QUOTE_REFERENCE = 'knbr/customer/${customerId}/carts/${cartId}/entries/${entryNumber}/quotes';
export const KN_BR_GET_QUOTE_REFERENCE_BY_PRODUCT = 'knbr/customer/${customerId}/quoteRef/${productId}';
export const KN_BR_UPDATE_QUOTE_REFERENCE =
  'knbr/customer/${customerId}/carts/${cartId}/entries/${entryNumber}?fields=DEFAULT';
export const KN_BR_REMOVE_QUOTE_REFERENCE =
  'knbr/customer/${customerId}/carts/${cartId}/entries/${entryNumber}/removeQuoteRef';

export const KN_BR_QUOTE_APPROVER_ACTION = 'knbr/approverAction';
export const KN_BR_RESET_PASSWORD = 'knbrcustomer/resetpassword';
export const KNBR_QUOTE_DOWNLOAD = 'knbr/customer/${customerId}/quotes/${quoteId}/download';
export const KNBR_DRAFT_ORDER_DOWNLOAD = 'knbr/customer/${customerId}/carts/${cartId}/download';
export const KNBR_QUOTE_ATTACHMENT_UPLOAD = 'knbr/customer/${customerId}/quotes/${quoteId}/attachment';
export const KNBR_DRAFT_ORDER_ATTACHMENT_UPLOAD = 'knbr/customer/${customerId}/carts/${cartId}/attachment';
export const KN_BR_AUTHENTICATE = 'knbr/authenticate';

export const KNBR_ORDER_DOCS_DOWNLOAD = 'knbr/documents/documentsbinarydata';
export const KNBR_ORDER_DOCS_DOWNLOAD_SINGLE = 'knbr/documents/binarydata';
export const KNBR_ORDER_HISTORY_SEARCH = (customerId: string) => `knbr/customer/${customerId}/orders`;
export const KNBR_ORDER_STATUS_LIST = (customerId: string) => `knbr/customer/${customerId}/orders/status`;
export const KNBR_BRAND_SALES_SHIPTO_LIST = (customerId: string) => `knbr/customer/${customerId}/orders/salesArea`;
export const KNBR_BRAND_ACTIVE_SALES = (customerId: string) => `knbr/customer/${customerId}/activeOrg`;

export const KNBR_BRAND_LIST = (customerId: string) => `knbr/customer/${customerId}/activeBrands`;
export const KNBR_CATEGORY_LIST_FOR_BRAND = (customerId: string) => `knbr/customer/${customerId}/brands/categories?brands=`;
export const KNBR_DOWNLOAD_BRAND_LIST = (customerId: string) => `knbr/customer/${customerId}/downloadPriceSheet`;
export const KNBR_SEND_PRICE_EMAIL = (customerId: string) => `knbr/customers/${customerId}/sendPriceSheetEmail`;
export const KN_BR_BREAD_CRUMBS = (customerId: string, productCode: string, salesOrg: string) => `knbr/customers/${customerId}/products/${productCode}/salesOrg/${salesOrg}/breadCrumb`;
export const KNBR_CANCEL_ORDER_STATUS_LIST = (customerId: string) =>
  `knbr/customer/${customerId}/orders/cancelStatuses`;
export const KNBR_CREATE_RETURN_ORDER = (customerId: string) => `knbr/customer/${customerId}/orderReturns`;
export const KNBR_ORDER_RETURN_REASON = (customerId: string) => `knbr/customer/${customerId}/orderReturns/returnReason`;
export const KNBR_ORDER_REORDER = (customerId: string) => `knbr/customer/${customerId}/cartFromOrder`;
export const KNBR_CANCEL_ORDER = 'knbr/customer/${customerId}/orders/${code}/cancellation';
export const KNBR_RETURN_ORDER_SEARCH = 'knbr/customer/${customerId}/orderReturns';
export const KNBR_RETURN_ORDER_STATUS_LIST = 'knbr/customer/${customerId}/orderReturns/statuses';
export const KNBR_INVOICE_SEARCH = (customerId: string) => `knbr/customer/${customerId}/b2bdocuments`;
export const KNBR_INVOICE_SEARCH_XLS_DOWNALOD = (customerId: string) => `knbr/customers/${customerId}/downloadInvoices`;
export const KNBR_INVOICE_DUES_AMOUNT = (customerId: string) => `knbr/customers/${customerId}/b2bdocuments/duesAmount`;
export const KNBR_INVOICE_ACCOUNT_SUMMARY = (customerId: string) => `knbr/customer/${customerId}/accountSummary`;
export const KNBR_INVOICE_STATUS_LIST = (customerId: string) => `knbr/customer/${customerId}/b2bdocuments/Statuses`;
export const KNBR_INVOICE_DOCUMENT_TYPE_LIST = (customerId: string) =>
  `knbr/customer/${customerId}/b2bdocuments/documentType`;
export const KNBR_INVOICE_FILTER_BY_LIST = (customerId: string) => `knbr/customer/${customerId}/filterByDropdown`;
export const KNBR_ORDER_ACTIONS = 'knbr/customer/${customerId}/orders/${code}/actions';
export const KNBR_PRODUCT_SEARCH_ORDER_FORMS =
  'knbr/customer/${customerId}/orderforms?fields=products(code,name,summary,price(FULL),images(DEFAULT),stock(FULL),averageRating,variantOptions,uom,minOrderQuantity),facets,breadcrumbs,pagination(DEFAULT),sorts(DEFAULT),freeTextSearch,currentQuery';
export const KNBR_ORDER_IMPORT = (customerId: string) => `knbr/customer/${customerId}/import/csv`;
export const KNBR_EDIT_ORDER = 'knbr/customer/${customerId}/orders/${code}/editOrder';
export const KNBR_GET_LANDING_PAGE = 'knbr/page/redirection';
export const KNBR_SERVICE_TICKET_CATEGORY_LIST = 'knbr/customer/${customerId}/ticketCategory';
export const KNBR_CREATE_SERVICE_TICKET = 'knbr/customer/${customerId}/add-support-ticket';
export const KNBR_ONE_TIME_ADDRESS_ORDER = 'knbr/customer/${customerId}/carts/${cartId}/addresses/delivery';
export const KN_BR_DRAFT_ORDER_QUICK_ENTRY = 'knbr/customer/${customerId}/carts/${cartId}/quickentry';
export const KN_BR_QUOTE_QUICK_ENTRY = 'knbr/customer/${customerId}/quotes/${quoteId}/quickentry';
export const KNBR_GET_JOB_FUNCTIONS = 'knbr/jobFunction';
export const KNBR_MY_ACCOUNT_PRICE_LIST = 'knbr/customer/${customerId}/priceList';
export const KNBR_DRAFT_ORDER_IMAGE_DOWNLOAD = 'knbr/customer/${customerId}/carts/${cartId}/getEntryMedias';
export const KNBR_DRAFT_ORDER_CSV_DOWNLOAD = 'knbr/customer/${customerId}/export/csv/download';
export const KN_BR_QUOTE_ACCEPT_AND_CHECKOUT = 'knbr/customer/${customerId}/quotes/${quoteId}/checkout';
export const KNBR_DRAFT_ORDER_DETAIL_CSV_DOWNLOAD = 'knbr/customer/${customerId}/carts/${cartId}/export';

export const KNBR_FACT_SHEET_DETAILS = (customerId: string) => `knbr/customer/${customerId}/factsheet`;
export const KNBR_FACT_SHEET_PDF = (customerId: string) => `knbr/customers/${customerId}/factSheet/pdf`;

export const KNBR_CONTACT_USER_INFO = (customerId: string) => `knbr/customers/${customerId}/contact-us/info`;
export const KNBR_CONTACT_COUNTRY = (customerId: string) => `knbr/customers/${customerId}/contact-us/info/countries`;
export const KNBR_CONTACT_STATE = (customerId: string, countryId: string) => `knbr/customers/${customerId}/contact-us/info/countries/${countryId}`;
export const KNBR_CONTACT_SUBMIT = (customerId: string) => `knbr/customer/${customerId}/contact-us/info/query`;
export const GET_USER_PK = 'knbr/customer/info/userPK';
export const KNBR_MULTI_DOWNLOAD = (customerId: string, param1, param2) => `knbr/customers/${customerId}/${param1}/download${param2}`;
export const KNBR_INVOICE_DETAILS = (customerId, invoiceNumber) => `knbr/customers/${customerId}/b2bdocuments/${invoiceNumber}`;
